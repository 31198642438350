<template>
   <article class="message">
        <div class="message-header">
            <h1>É necessário cadastrar um item do tipo</h1>
        </div>
        <div class="message-body">
            <h1>Para cadastrar um subitem do tipo <b>{{ tipoItemSelecionado }}</b>, é necessário cadastrar previamente no menu selecionado um item do tipo:</h1>
            <div class="content">
            <ul>
                <li>Avatar</li>
                <li>DropDown</li>
            </ul>
            </div>
        </div>
    </article>
</template>

<script>
export default {
    props: {
        tipoItemSelecionado: String
    }
}
</script>

<style>

</style>