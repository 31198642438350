export default class DropDownCadastroColunas {
    constructor(objeto) {
        this.text = ""
        this.idPai = ""
        this.idMenu = ""
        Object.assign(this, objeto)
    }

    dropDownCadastroColunasProps() {
        return {
            clickEvent: "",
            position: "",
            hideOnDevice: "",
            customClass: "",
            idElement: "",
            forceNavbarTop: "",
            classesToPreventCloseOnClick:[],
            coluna: true,
            dropDown: false,
            customElement: false,
            avatar: false,
            customItem: false
        }
    }
}