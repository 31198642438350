<template>
    <div class="modal">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Confirmar Ação</p>
                <button class="btnModalConfirmacao delete" @click="fecharModal(false)" aria-label="close"></button>
            </header>
            <section class="modal-card-body">
                {{ mensagem }}
            </section>
            <footer class="modal-card-foot ">
                <button class="btnModalConfirmacao button is-danger" @click="fecharModal(true)">Confirmar</button>
                <button class="btnModalConfirmacao button" @click="fecharModal(false)">Cancelar</button>
            </footer>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            mensagem: String,
        },
        methods: {
            fecharModal(confirm){
                this.$emit('modalFechado', confirm)
            }
        }
    };
</script>
