<template>
    <ValidationProvider :name="titulo" rules="required">
        <div slot-scope="{ errors }" class="column is-12 is-paddingless is-pulled-left">
            <span>{{title}}</span>
            <div class="column is-12 is-paddingless is-pulled-left"
                :class="{'margin-bottom': !errors.length}">
                <div class="column is-12 is-pulled-left is-paddingless inputCores">
                    <input v-model="color" type="text"
                    class="input has-padding-left-50 is-small"
                    :class="{'is-danger' : errors.length}">
                </div>
                <div class="column is-4 is-paddingless is-pulled-left">
                    <input class="paletaDeCor"
                    :style="[!color ? {'background': '#000000'} : {'background': color}]"
                    type="color"
                    value=color
                    @change="corSelecionada"
                    v-model="color"
                    title="Clique para escolher a cor.">
                </div>
            </div>
            <small class="has-text-danger is-pulled-left">{{ errors[0] }}</small>
        </div>
    </ValidationProvider>
</template>

<script>
export default {
    props: {
        titulo: String,
        cor: String
    },
    data() {
        return {
            title: this.titulo,
            color: !this.cor ? "" :this.cor
        }
    },
    methods: {
        corSelecionada() {
            this.$emit('corSelecionada',this.color)
        }
    },
    watch: {
        cor: {
            handler(){
                this.color = !this.cor ? "" :this.cor
            },
            deep: true
        },
        color: {
            handler(){
                this.corSelecionada()
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>

input[type="color"], input[type="color"]:focus {
    -webkit-appearance: none;
    border: 1px solid #dbdbdbde;
}
input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}
input[type="color"]::-webkit-color-swatch {
    border: none;
}

input[type="color" i] {
    width: 44px;
    height: 30px;
}

.paletaDeCor {
    border: 1px solid transparent;
    border-radius: 5px;
    float: left;
    margin-top: -30px;
    position: relative;
    cursor: pointer;
}
.paletaDeCor:focus {
    border: 1px solid #dbdbdbde !important;
}

.value {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>