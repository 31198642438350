import { ItensService } from "@/services/itens.servico";

export const itensModulo = {
  state: {
    idItem: {},
    listaItens: [],
    itemSalvo: false,
  },

  mutations: {
    listaItens(state, payload) {
      state.listaItens = payload;
    },
    edicao(state, payload) {
      state.idItem = payload;
    },
    itemSalvo(state, payload) {
      state.itemSalvo = payload;
    },
  },

  actions: {
    buscarListaItens({ commit, dispatch }, payload) {
      ItensService.buscarListaItens(commit, dispatch, payload);
    },

    publicarIten({ commit, dispatch }, payload) {
      ItensService.publicarIten(commit, dispatch, payload);
    },

    deletarItens({ commit, dispatch }, payload) {
      ItensService.deletarItens(commit, dispatch, payload);
    },
    redicionarModalPesquisa() {
      this.commit("itemSalvo", true);
    },
  },
};
