const storeConfig = {

    state: {
        webServiceLoading: false,
        showModal: false,
        tipoCrud: "",
        exibirMensagemWebService: {
          exibirMensagem: false,
          tipoMensagem: "",
          textoMensagem: "",
        },
        dadosModal:{
          exibirModal: '',
        },
        editando: false,
        listaFiltrada: []
      },
      mutations: {
        listaFiltrada(state,payload) {
          state.listaFiltrada = payload
        },
        tipoCrud(state,payload) {
          state.tipoCrud = payload
        },
        exibirModal(state, payload) {
          state.showModal = payload
        },
        preencherMensagemWebService(state, payload) {
          state.exibirMensagemWebService = payload
        },
        fecharMensagem(state, payload) {
          state.exibirMensagemWebService.exibirMensagem = payload
        },
        exibirOcultarModalLoading(state,payload) {
          state.dadosModal.exibirModal = payload
        },
        validacaoEdicao(state,payload) {
          state.editando = payload
        },
    },
      
    actions: {
        montarMensagemErro({commit}, payload) {
          let message = {
            exibirMensagem: true,
            tipoMensagem: "erro",
            textoMensagem: payload.message ? payload.message : payload
          }
          commit('preencherMensagemWebService', message)
        },
    
        montarMensagemSucesso({commit}, payload) {
          let message = {
            exibirMensagem: true,
            tipoMensagem: "sucesso",
            textoMensagem: payload,
          }
          commit('preencherMensagemWebService', message)
        }
    }
}

export default storeConfig