export default class CadastroBotao {

    constructor(objeto) {
        this.text = ""
        this.clickEvent = ""
        this.href = ""
        this.position = ""
        this.hideOnDevice = ""
        this.customClass = ""
        this.idElement = ""
        this.forceNavbarTop = ""
        this.idMenu = ""

        Object.assign(this, objeto)
    }

    CadastroBotaoProps() {
        return {
            coluna: false,
            dropDown: false,
            customElement: false,
            avatar: false,
            customItem: false
        }
    }

}