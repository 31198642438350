<template>
  <div class="card column is-12 is-pulled-left is-paddingless has-padding-right-10 has-padding-left-10">
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit">
        <div class="column is-12 is-pulled-left is-paddingless">
          <ValidationProvider class="column is-6 is-pulled-left is-paddingless has-padding-right-10" name="ID" rules="required">
            <div slot-scope="{ errors }">
              <span>Infome o ID do sistema</span>
              <div class="select is-fullwidth">
                <select
                  class="input is-small"
                  v-model="parametros.sistemaId"
                  :class="{'is-danger' : errors.length, 'margin-bottom': !errors.length}">
                  <option></option>
                  <option v-for="(sistema, i) in listaSistemas"
                    :key="i"
                    :value="sistema.id">
                    {{sistema.nome + " - " + sistema.id}}</option>
                </select>
              </div>
              <small class="has-text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider class="column is-6 is-pulled-left is-paddingless has-padding-left-10" name="Descrição" rules="required">
            <div slot-scope="{ errors }">
              <span>Digite uma descrição para o template</span>
              <input class="input is-small"
                v-model="parametros.descricao"
                type="text" 
                :class="{'is-danger' : errors.length, 'margin-bottom': !errors.length}">
              <small class="has-text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-bottom-5">
          <div class="column is-6 is-pulled-left is-paddingless has-padding-right-10">
            <div class="column is-4 is-paddingless is-pulled-left has-padding-right-10 ">
              <paletaCores
                titulo="Cor de fundo"
                :cor="parametros.backgroundColor"
                @corSelecionada="parametros.backgroundColor = $event"/>
            </div>
            <div class="column is-4 is-pulled-left is-paddingless has-padding-right-10 has-padding-left-10">
              <paletaCores
                titulo="Cor do texto"
                :cor="parametros.color"
                @corSelecionada="parametros.color = $event"/>
            </div>
            <div class="column is-4 is-pulled-left is-paddingless has-padding-left-10">
              <paletaCores
                titulo="Cor de mouse sobre"
                :cor="parametros.hoverColor"
                @corSelecionada="parametros.hoverColor = $event"/>
            </div>
          </div>
          <div class="column is-6 is-pulled-left is-paddingless has-padding-left-10">
            <span>Classes personalizadas</span>
            <input type="text" class="input is-small" v-model="parametros.customClass"/>
          </div>
        </div>
        <hr class="column is-12 is-pulled-left is-marginless is-paddingless"/>
        <p><span>Parâmetros do Logotipo</span></p>
        <div class="column is-12 is-pulled-left">
          <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-6 is-pulled-left is-paddingless has-padding-right-10">
              <div class="column is-12 is-pulled-left is-paddingless has-padding-right-10 has-padding-bottom-10">
                <span>Rota ao clicar</span>
                <input 
                  type="text"
                  class="input is-small"
                  v-model="parametros.logos[0].href">
              </div>
              <ValidationProvider class="column is-6 is-paddingless is-pulled-left has-padding-right-10" name="Posição" rules="required">
                <div slot-scope="{ errors }">
                  <span>Posição</span>
                  <div class="select is-fullwidth is-small">
                    <select v-model="parametros.logos[0].position" 
                      class="input is-small"
                      :class="{'is-danger' : errors.length, 'margin-bottom': !errors.length}"> 
                      <option></option>  
                      <option v-for="(position, i) in positionOptions"
                        :key="i"
                        :value="position.codigo">
                        {{position.descricao}}
                      </option>
                    </select>
                    <small class="has-text-danger">{{ errors[0] }}</small>
                  </div>
                </div>
              </ValidationProvider>
              <ValidationProvider class="column is-6 is-paddingless is-pulled-left has-padding-right-10" name="Esconder para" rules="required">
                <div slot-scope="{ errors }">
                <span>Esconder para</span>
                <div class="select is-fullwidth is-small">
                  <select v-model="parametros.logos[0].hideOnDevice"
                  :class="{'is-danger' : errors.length, 'margin-bottom': !errors.length}" 
                    class="input is-small">   
                    <option></option>
                    <option v-for="(device, i) in hideOnDevice"
                      :key="i"
                      :value="device.codigo">
                      {{device.descricao}}
                    </option>
                  </select>
                  <small class="has-text-danger">{{ errors[0] }}</small>
                </div>
              </div>
              </ValidationProvider>
              <div class="column is-6 is-paddingless has-padding-top-15 is-pulled-left has-padding-right-10 has-padding-bottom-10">
                <span>Classe personalizada</span>
                <input
                  type="text"
                  class="input is-small"
                  v-model="parametros.logos[0].customClass">
              </div>
              <div class="column is-6 is-paddingless has-padding-top-15 is-pulled-left has-padding-left-10 has-padding-right-10 has-padding-bottom-10" name="Evento ao clicar" rules="required">
                <span>Evento ao clicar</span>
                <input 
                  type="text"
                  class="input is-small"
                  v-model="parametros.logos[0].clickEvent">
              </div>
              <div class="column is-12 is-paddingless is-pulled-left has-padding-right-10">
                <span>Identificação para logo</span>
                <input 
                  type="text"
                  class="input is-small"
                  v-model="parametros.logos[0].idElement">
              </div>
            </div>
            <div class="column is-6 is-pulled-left is-paddingless">
              <div>
                <ValidationProvider class="column is-9 is-pulled-left is-paddingless" 
                  rules="required"
                  name="Logotipo">
                  <div slot-scope="{ errors }"> 
                    <span>Informe o caminho ou selecione uma imagem para logo</span>
                    <input v-if="nomeArquivo" 
                      name="campo" 
                      id="campo"
                      type="text"
                      :class="{'is-danger' : errors.length, 'margin-bottom': !errors.length}"
                      class="input is-small"
                      v-model="nomeArquivo"
                      placeholder="nome arquivo">
                    <input v-else
                      name="campo1" 
                      id="campo1"
                      type="text"
                      :class="{'is-danger' : errors.length, 'margin-bottom': !errors.length}"
                      class="input is-small"
                      v-model="parametros.logos[0].src"
                      placeholder="SRC">
                    <small class="has-text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
                <file-reader
                  class="button is-small btn-file-reader"
                  @load="carregarArquivo($event)"/>
              </div>
              <div class="column is-12 card containerLogotipo">
                <span v-if="nomeArquivo === '' && validarBase64(parametros.logos[0].src)" class="is-pulled-left is-size-5 has-padding-30">Não foi selecionada uma imagem para o logotipo</span>
                <div v-else >
                  <img class="logotipo" :src="`${parametros.logos[0].src}`" alt="imagem da logo"/>
                </div>
              </div>
              <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
                <div :class="{ 'column is-3' : botaoDeletar, 'column is-4': !botaoDeletar }" class="is-pulled-right is-paddingless has-padding-left-10">
                  <button class="button is-fullwidth" type="submit">
                  <i  class="fas fa-save has-padding-right-5 is-size-6" aria-hidden="true"></i>Salvar</button>
                </div>
                <div :class="{ 'column is-3' : botaoDeletar, 'column is-4': !botaoDeletar }" class="is-pulled-right is-paddingless has-padding-right-10">
                  <button type="button" class="btnCarregarPreview button is-fullwidth" @click="carregarPreview">
                  <i  class="fas fa-eye has-padding-right-5 is-size-6" aria-hidden="true"></i>Preview</button>
                </div>
                <div v-if="this.botaoDeletar" :class="{ 'column is-3' : botaoDeletar, 'column is-4': !botaoDeletar }"  class="is-pulled-right is-paddingless has-padding-right-20">
                  <button type="button" class="button is-fullwidth" value="Remover" @click="exibirModal()">
                  <i  class="fa fa-trash has-padding-right-5 is-size-6" aria-hidden="true"></i>Deletar</button>
                </div>
                <div :class="{ 'column is-3' : botaoDeletar, 'column is-4': !botaoDeletar }" class="is-pulled-right is-paddingless has-padding-right-20">
                  <button type="reset" class="button is-fullwidth" value="Limpa" @click="limpa()">
                  <i  class="btnLimpa fa fa-eraser has-padding-right-5 is-size-5" aria-hidden="true"></i>Limpar</button>
                </div>
              </div>
            </div>
        </div>
        </div>
      </form>
    </ValidationObserver>
    <modal-confirmacao 
      :mensagem="this.mensagem" 
      :class="exibirModalConfirmacao" 
      @modalFechado="exibirModal">
    </modal-confirmacao>
    <modal v-if="showModal"
      :dadosMenu="parametros"></modal>
  </div>
</template>

<script>
import ParserRequisicoes from "@/helpers/parserRequisicoes"
import FileReader from "@/components/importarArquivo"
import CadastroMenu from "@/model/menu/cadastroMenu"
import ModalConfirmacao from "@/components/modal/modalConfirmacao"
import paletaCores from "@/components/paletaCores"

export default {
  
  components: {
    FileReader,
    ModalConfirmacao,
    paletaCores
  },
  props: {
    dadosSelecao: Object,
  },
  computed: {
    showModal() {
      return this.$store.state.showModal
    },
    mensagemWebService() {
      return this.$store.state.exibirMensagemWebService
    },
    botaoDeletar() {
      return this.$store.state.editando
    },
    listaSistemas(){
      return this.$store.state.sistemaModulo.listaSistemas
    },
  },
  data() {
    return {
      classesPadroes: [
        'is-pulled-right',
        'is-paddingless'
      ],
      mensagem: "",
      exibirModalConfirmacao: "",
      confirmacaoExclusao: false,
      parametros: new CadastroMenu(),
      nomeArquivo:'',
      dadoSelecao: this.dadosSelecao,
      positionOptions: [
        {descricao: "Esquerda", codigo: "left"},
        {descricao: "Centro", codigo: "center"},
        {descricao: "Direita", codigo: "right"} 
      ],
      hideOnDevice: [
        {descricao: "Desktop", codigo: "desktop"},
        {descricao: "Mobile", codigo: "mobile"}
      ]
    };
  },
  methods: {
    limpa() {
      this.nomeArquivo = ""
      this.dadoSelecao = {}
      this.parametros = new CadastroMenu()
      this.$refs.form.reset()
    },
    carregarPreview() {
      this.$refs.form.validate().then(success => {
        if(success){
          this.$store.commit('exibirModal', true)
        }
      })
    },
    validarBase64(str) {     
      if((str.substring(0, 15) === 'data:image/jpeg') || (str.substring(0, 14) === 'data:image/png') || (str.substring(0, 14) === 'data:image/jpg')) {
        this.nomeArquivo = "Carregado arquivo base64."
        return false
      }
        return true
      
    },
    carregarArquivo(param) {
      this.parametros.logos[0].src = param.file;
      this.nomeArquivo = param.name;
    },
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if(success){
          let dadosRequisicao = {
            parametros : this.parametros,
            idMenu :  this.dadoSelecao.id
          }

          this.limpa()
          this.$store.dispatch('publicaMenu', dadosRequisicao)
          this.$emit('abrirListaMenus')
        }
      })
    },
    deletarMenu(id) {
      this.$store.dispatch('deletarMenu',id)
      if(this.mensagemWebService.tipoMensagem !== 'erro')
        this.limpa()
    },
    deletarItem(id) {
      this.$store.dispatch('deletarItens',id)
    },
    mensagens(){
      this.mensagem = this.dadosSelecao.itens.length ? 'Este menu possui itens cadastrados que serão excluidos junto, deseja realmente continuar?' : 'Deseja realmente excluir este menu?'
    },
    deletaItens(){
      if(this.dadosSelecao.itens.length > 0){
        let itemId = []
        let arrayItens = this.dadosSelecao.itens
        for(let index=0; index < this.dadosSelecao.itens.length; index++){
          const element = arrayItens[index]
          itemId.push(element)
          this.deletarItem(itemId[index].id)
          return itemId
        }
      }
    },
    exibirModal(payload) {
      this.mensagens()

      if(this.exibirModalConfirmacao === "") {
        this.exibirModalConfirmacao = 'is-active'
      }else{
        this.exibirModalConfirmacao = "" 
        this.confirmacaoExclusao = payload
      } 

      if(this.confirmacaoExclusao){
        if(this.dadosSelecao.itens.length > 0){
          this.deletaItens()
          this.deletarMenu(this.dadoSelecao.id)
        }else {
          this.deletarMenu(this.dadoSelecao.id);
        }
      }
    },
    buscaListaSistema(){
      this.parametros =  JSON.stringify(this.dadoSelecao) === '{}' ? this.parametros : new ParserRequisicoes().parserMenu(this.dadoSelecao);
      this.$store.dispatch('buscarListaSistemas')
    }
  },
  created() {
    this.buscaListaSistema()
  }
    
};
</script>

<style lang="scss" scoped>
.containerLogotipo{
  float: left;
  align-content: center;
  width: 100%;
  text-align: center;
  height: 150px;
}

.logotipo{
  width: auto;
  height: 120px;
  margin: auto;
}

</style>