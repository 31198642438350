export default class cadastroMenu {
    constructor(objeto) {
        this.navbarId = 'navbarId'
        this.customElementShadowRoot = "mf-atena-cadastro-menu"
        this.sistemaId = ""
        this.descricao = ""
        this.backgroundColor = ""
        this.color = ""
        this.hoverColor = ""
        this.logos = [{
            src: "",
            href: "",
            position: "",
            hideOnDevice: "",
            customClass: "",
            clickEvent: "",
            idElement: "",
        }]
        Object.assign(this, objeto)
    }
}