<template>
  <div>
    <div
      class="column is-12 is-pulled-left"
      v-if="
        !Object.keys(this.dropDownItems).length && !dropDownSelecionada === ''
      "
    >
      <MensagemItemNaoEncontrado :tipoItemSelecionado="tipoItemSelecionado" />
    </div>
    <div v-else>
      <ValidationObserver ref="form">
        <form @submit.prevent="onSubmit">
          <div class="column is-12 is-pulled-left is-paddingless">
            <ValidationProvider
              v-if="!itensMenus"
              class="column is-6 is-pulled-left is-paddingless has-padding-right-10"
              name="Item para inclusão"
              rules="required"
            >
              <div slot-scope="{ errors }">
                <span>Informe o item do menu para inclusão</span>
                <div class="select is-fullwidth is-small">
                  <select
                    v-model="dropDownSelecionada"
                    class="input is-small"
                    :class="{
                      'is-danger': errors.length,
                      'margin-bottom': !errors.length,
                    }"
                    @change="preencherListaColunas"
                  >
                    <option
                      v-for="(dropDown, i) in dropDownItems"
                      :key="i"
                      :value="dropDown"
                    >
                      {{ dropDown.text + " - " + dropDown.id }}
                    </option>
                  </select>
                  <small class="has-text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider
              v-if="!itensMenus"
              class="column is-6 is-pulled-left is-paddingless has-padding-left-10"
              name="Coluna para inclusão"
              rules="required"
            >
              <div slot-scope="{ errors }">
                <span>Informe a coluna para inclusão do item</span>
                <div class="select is-fullwidth is-small">
                  <select
                    v-model="iditemFilho"
                    class="input is-small"
                    :class="{
                      'is-danger': errors.length,
                      'margin-bottom': !errors.length,
                    }"
                  >
                    <option
                      v-if="
                        !Object.keys(itensFilhosLista).length &&
                          dropDownSelecionada
                      "
                      value=""
                      disabled
                      selected
                      >Sem Colunas Cadastradas para inclusão do item.</option
                    >
                    <option
                      v-for="(dropDown, i) in itensFilhosLista"
                      :key="i"
                      :value="dropDown.id"
                    >
                      {{
                        dropDown.text
                          ? dropDown.text + " - " + dropDown.id
                          : dropDown.id
                      }}
                    </option>
                  </select>
                  <small class="has-text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </ValidationProvider>
            <div
              class="column is-12 is-pulled-left is-paddingless has-padding-top-15"
            >
              <ValidationProvider
                class="column is-4 is-pulled-left is-paddingless has-padding-right-10"
                name="Descrição"
                rules="required"
              >
                <div slot-scope="{ errors }">
                  <span>Digite uma descrição</span>
                  <input
                    class="input is-small"
                    v-model="parametros.text"
                    type="text"
                    :class="{
                      'is-danger': errors.length,
                      'margin-bottom': !errors.length,
                    }"
                  />
                  <small class="has-text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>

              <div
                class="column is-4 is-pulled-left is-paddingless has-padding-left-10 has-padding-right-10"
              >
                <span>Rota ao clicar</span>
                <input
                  type="text"
                  class="input is-small"
                  v-model="parametros.href"
                />
              </div>
              <div
                class="column is-4 is-pulled-left is-paddingless has-padding-left-10"
              >
                <span>Id para o elemento</span>
                <input
                  type="text"
                  class="input is-small"
                  v-model="parametros.idElement"
                />
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div
        class="column is-12 is-pulled-left is-paddingless has-padding-top-20"
      >
        <div class="column is-2 is-pulled-right is-paddingless">
          <button type="button" class="button is-fullwidth" @click="onSubmit">
            <i class="fas fa-save has-padding-right-5" aria-hidden="true"></i
            >Salvar
          </button>
        </div>
        <DeletarItens @limpar="limpa"></DeletarItens>
        <div
          class="column is-2 is-pulled-right is-paddingless has-padding-right-20"
        >
          <button
            type="reset"
            class="btnLimpa button is-fullwidth"
            value="Limpa"
            @click="limpa()"
          >
            <i
              class="fa fa-eraser has-padding-right-5 is-size-5"
              aria-hidden="true"
            ></i
            >Limpar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropDownCadastroItens from "@/model/itens/dropDownCadastroItens";
import DeletarItens from "@/components/deletarItens";
import MensagemItemNaoEncontrado from "@/utils/mensagemItemNaoEncontrado";

export default {
  props: {
    menuSelecionado: Object,
    tipoItemSelecionado: String,
  },
  components: {
    DeletarItens,
    MensagemItemNaoEncontrado,
  },
  computed: {
    dropDownItems() {
      return this.somenteDropDowns();
    },
    itensFilhosLista() {
      return this.preencherListaColunas();
    },
    idItens() {
      return this.$store.state.itensModulo.idItem;
    },
    itensMenus() {
      return this.$store.state.editando;
    },
  },
  data() {
    return {
      dropDownSelecionada: "",
      iditemFilho: "",
      parametros: new DropDownCadastroItens(),
    };
  },
  methods: {
    limpa() {
      this.parametros = new DropDownCadastroItens();
      this.$store.commit("edicao", {});
      this.$emit("limpar");
      this.$refs.form.reset();
    },

    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.parametros.idPai = this.iditemFilho;
          this.parametros.idMenu = this.menuSelecionado.id;
          let payload = {
            ...this.parametros,
            ...this.parametros.dropDownCadastroItensProps(),
            parametros: this.parametros,
            idItem: this.idItens.id,
          };
          this.$store.dispatch("publicarIten", payload);
          this.parametros = new DropDownCadastroItens();
          this.dropDownSelecionada = "";
          this.iditemFilho = "";
          this.$emit("salvouObjeto");
          this.limpa();
          this.$store.dispatch("redicionarModalPesquisa");
        }
      });
    },

    somenteDropDowns() {
      let somenteDropDowns = [];
      let arrayItems = this.menuSelecionado.itens
        ? this.menuSelecionado.itens
        : [];
      for (let index = 0; index < arrayItems.length; index++) {
        const element = arrayItems[index];
        if (element.dropDown === true || element.avatar === true) {
          somenteDropDowns.push(element);
        }
      }
      return somenteDropDowns;
    },

    preencherListaColunas() {
      let listaColunas = [];
      let arrayItems = this.dropDownSelecionada.itensFilhos
        ? this.dropDownSelecionada.itensFilhos
        : [];
      for (let index = 0; index < arrayItems.length; index++) {
        const element = arrayItems[index];
        listaColunas.push(element);
      }
      return listaColunas;
    },

    valorDeEntradaEdicao() {
      if (this.menuSelecionado && this.idItens !== {}) {
        Object.assign(this.parametros, this.idItens);
        this.iditemFilho = this.idItens.idPai;
      }
    },
  },

  created() {
    this.valorDeEntradaEdicao();
  },
};
</script>
