<template>
    <div>
        <ValidationObserver ref="form">
            <form @submit.prevent="onSubmit">
                <div class="column is-12 is-pulled-left is-paddingless">
                    <ValidationProvider class="column is-6 is-pulled-left is-paddingless has-padding-right-10" name="Descrição" rules="required">
                        <div slot-scope="{ errors }">
                            <span>Digite uma descrição</span>
                            <input class="input is-small"
                                v-model="parametros.text"
                                type="text" 
                                :class="{'is-danger' : errors.length, 'margin-bottom': !errors.length}">
                            <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div class="column is-6 is-pulled-left is-paddingless has-padding-left-10">
                        <span>Evento ao clicar</span>
                        <input type="text"
                            class="input is-small"
                            v-model="parametros.clickEvent">
                    </div>
                </div>
                <div class="column is-12 is-pulled-left is-paddingless">
                    <div class="column is-4 is-pulled-left is-paddingless has-padding-right-10">
                        <span>Rota ao clicar</span>
                        <input type="text"
                            class="input is-small"
                            v-model="parametros.href">
                    </div>
                    <div class="column is-4 is-pulled-left is-paddingless has-padding-left-10 has-padding-right-10">
                        <span>Classe personalizada</span>
                        <input type="text"
                            class="input is-small"
                            v-model="parametros.customClass">
                    </div>
                    <div class="column is-4 is-pulled-left is-paddingless has-padding-left-10">
                        <span>Id para o elemento</span>
                        <input type="text"
                            class="input is-small"
                            v-model="parametros.idElement">
                    </div>
                </div>
                <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
                    <div class="column is-4 is-pulled-left is-paddingless has-padding-right-10">
                        <span>Esconder para</span>
                        <div class="select is-fullwidth is-small">
                            <select v-model="parametros.hideOnDevice"
                                class="input is-small">   
                                <option v-for="(device, i) in hideOnDevice"
                                    :key="i"
                                    :value="device.codigo">
                                    {{device.descricao}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <ValidationProvider class="column is-4 is-pulled-left is-paddingless has-padding-left-10" name="Posição" rules="required">
                        <div slot-scope="{ errors }">
                            <span>Posição</span>
                            <div class="select is-fullwidth is-small">
                                <select v-model="parametros.position" 
                                    class="input is-small"
                                    :class="{'is-danger' : errors.length, 'margin-bottom': !errors.length}">   
                                    <option v-for="(position, i) in positionOptions"
                                        :key="i"
                                        :value="position.codigo">
                                        {{position.descricao}}
                                    </option>
                                </select>
                                <small class="has-text-danger">{{ errors[0] }}</small>
                            </div>
                        </div>
                    </ValidationProvider>
                    <div class="column is-4 is-pulled-left is-paddingless">
                        <div class="is-pulled-left chkForceNavbarTop">
                            <input type="checkbox"
                                v-model="parametros.forceNavbarTop">
                        </div>
                        <div class="is-pulled-left has-padding-top-25">
                            <span>Forçar item a aparecer em baixa resolução</span>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-20">
            <div class="column is-2 is-pulled-right is-paddingless">
                <button class="button is-fullwidth" type="button" @click="onSubmit">
                    <i  class="fas fa-save has-padding-right-5" aria-hidden="true"></i>Salvar
                </button>
            </div>
            <DeletarItens @limpar="limpa"></DeletarItens>
            <div class="column is-2 is-pulled-right is-paddingless has-padding-right-20">
                <button type="reset" class="btnLimpa button is-fullwidth" value="Limpa" @click="limpa()">
                <i  class="fa fa-eraser has-padding-right-5 is-size-5" aria-hidden="true"></i>Limpar</button>
            </div>
        </div>
  </div>
</template>

<script>
import CadastroBotao from '@/model/itens/cadastroBotao'
import DeletarItens from "@/components/deletarItens"


export default {
    props: {
        menuSelecionado: Object
    },
    components: {
        DeletarItens
    },
    data() {
        return {
            parametros: new CadastroBotao(),
            positionOptions: [
                {descricao: "Esquerda", codigo: "left"},
                {descricao: "Centro", codigo: "center"},
                {descricao: "Direita", codigo: "right"} 
            ],
            hideOnDevice: [
                {descricao: "Desktop", codigo: "desktop"},
                {descricao: "Mobile", codigo: "mobile"}
            ]
        }
    },
    computed: {
        idItens() {
            return this.$store.state.itensModulo.idItem
        },
    },
    methods: {
        limpa(){
            this.parametros = new CadastroBotao()
            this.$store.commit("edicao", {})
            this.$emit('limpar')
            this.$refs.form.reset()
        },
        onSubmit() {
            this.$refs.form.validate().then(success => {
                if(success){
                    this.parametros.idMenu = this.menuSelecionado.id
                    let payload = {
                        ...this.parametros,
                        ...this.parametros.CadastroBotaoProps(),
                        parametros: this.parametros,
                        idItem: this.idItens.id
                    }
                    this.$store.dispatch('publicarIten', payload);
                    this.parametros = new CadastroBotao()
                    this.$emit('salvouObjeto')
                    this.limpa()
                    this.$store.dispatch("redicionarModalPesquisa");
                }
            })
        },
        valorDeEntradaEdicao(){
            if(this.menuSelecionado && this.idItens !== {}){
                Object.assign(this.parametros, this.idItens);
            }
            this.$store.dispatch('buscarListaMenu')
        }
    },
    created(){
        this.valorDeEntradaEdicao()
    }
}
</script>