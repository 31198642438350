<template>
  <div class="column is-12 is-pulled-left">
      <TemplatePreview>
        <navbar-vanilla id="navbarId"/>
      </TemplatePreview>
  </div>
</template>

<script>
/*global Navbar*/
import TemplatePreview from '@/components/templatePreview'

export default {
  components: {
    TemplatePreview
  },  
 
  computed: {
      dadosMenuSelecionado(){
        return this.$store.state.menuModulo.menuId
      }
  },

  methods: {
    previsualizar(process){
      if(process === 'development') {
        delete this.dadosMenuSelecionado.customElementShadowRoot
        this.navbar = new Navbar(this.dadosMenuSelecionado)
      }else{
        this.navbar = new Navbar(this.dadosMenuSelecionado)
      }
    }
  },

  mounted() {
    this.previsualizar(process.env.NODE_ENV)
  }
};
</script>

