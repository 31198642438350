<template>
  <div id="app">
    <script
      type="module"
      src="https://cdn-erpforme.alterdata.com.br/nimbus/atena/mf/atena-utils/atena-utils.min.js"
    />
    <div
      class="column is-12 is-pulled-left is-marginless is-paddingless has-text-centered"
    >
      <span
        class="btnRetornarPaginaInicial titulo is-pulled-left has-margin-left-30 is-size-4"
        @click="retornarPaginaInicial('Apresentacao')"
      >
        Consulta e cadastro de templates para o Vanilla Menu
      </span>
    </div>
    <hr class="column is-12 is-pulled-left is-marginless is-paddingless has-margin-bottom-10"/>
    <section class="columns pt-2 px-5">
      <aside class="column is-2 menu">
        <p class="menu-label">Funcionalidades</p>
        <ul class="menu-list">
          <li>
            <i
              class="fas fa-table has-padding-left-10 fa-sm"
              aria-hidden="true"
            ></i>
            Sistema
            <ul class="has-margin-left-10">
              <li>
                <a
                  class="btnComponenteSelecionado"
                  @click="componenteSelecionado('ListaSistemas')"
                  :class="{ selecionado: classeSelecionada('ListaSistemas') }"
                >
                  <i class="fas fa-search fa-sm" aria-hidden="true"></i>
                  Pesquisa
                </a>
              </li>
              <li>
                <a
                  class="btnComponenteSelecionado"
                  @click="componenteSelecionado('CadastroSistema')"
                  :class="{ selecionado: classeSelecionada('CadastroSistema') }"
                >
                  <i class="fas fa-plus-square fa-sm" aria-hidden="true"></i>
                  Cadastro
                </a>
              </li>
            </ul>
          </li>
          <li>
            <i
              class="fas fa-table has-padding-left-10 fa-sm"
              aria-hidden="true"
            ></i>
            Menu
            <ul class="has-margin-left-10">
              <li>
                <a
                  class="btnComponenteSelecionado"
                  @click="componenteSelecionado('ListaMenus')"
                  :class="{ selecionado: classeSelecionada('ListaMenus') }"
                >
                  <i class="fas fa-search fa-sm" aria-hidden="true"></i>
                  Pesquisa
                </a>
              </li>
              <li>
                <a
                  class="btnComponenteSelecionado"
                  @click="componenteSelecionado('CadastroMenu')"
                  :class="{ selecionado: classeSelecionada('CadastroMenu') }"
                >
                  <i class="fas fa-plus-square fa-sm" aria-hidden="true"></i>
                  Cadastro
                </a>
              </li>
            </ul>
          </li>
          <li>
            <i
              class="fas fa-sitemap has-padding-left-10 fa-sm"
              aria-hidden="true"
            ></i>
            Itens do Menu
            <ul class="has-margin-left-10">
              <li>
                <a
                  class="btnComponenteSelecionado"
                  @click="componenteSelecionado('ListaItensMenu')"
                  :class="{ selecionado: classeSelecionada('ListaItensMenu') }"
                >
                  <i class="fas fa-search fa-sm" aria-hidden="true"></i>
                  Pesquisa
                </a>
              </li>
              <li>
                <a
                  class="btnComponenteSelecionado"
                  @click="componenteSelecionado('SelecaoMenuAlteracao')"
                  :class="{
                    selecionado: classeSelecionada('SelecaoMenuAlteracao'),
                  }"
                >
                  <i class="fas fa-plus-square fa-sm" aria-hidden="true"></i>
                  Cadastro
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </aside>
      <div class="column is-10">
          <component
            @abrirModalPesquisa="componenteSelecionado('ListaSistemas')"
            @abrirListaMenus="componenteSelecionado('ListaMenus')"
            v-bind:is="componente"
            :editarSelecao="editarSelecao"
            :dadosSelecao="dadosSelecao">
          </component>
      </div>
    </section>
    <atena-utils-mensagens-retorno
      v-if="exibirMensagem"
      :parametros-mensagem="parametrosMensagem"
      :tipo-crud="tipoMensagem"
    />
    <atena-utils-modal-loading v-if="dadosModal.exibirModal" />
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, required_if } from "vee-validate/dist/rules";

import Apresentacao from "@/view/apresentacao";
import PaletaCores from "@/components/paletaCores";
import ListaSistemas from "@/view/sistema/listaSistemas";
import CadastroSistema from "@/view/sistema/cadastroSistema";
import ListaMenus from "@/view/menu/listaMenus";
import CadastroMenu from "@/view/menu/cadastroMenu";
import ListaItensMenu from "@/view/itens/listaItensMenu";
import SelecaoMenuAlteracao from "@/view/itens/selecaoMenuAlteracao";
import Modal from "@/components/modal/modal";
import MensagemItemNaoEncontrado from "@/utils/mensagemItemNaoEncontrado";

extend("required", {
  ...required,
  message: "{_field_} é obrigatório.",
});

extend("required_if", {
  ...required_if,
  message: "{_field_} é obrigatório.",
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("PaletaCores", PaletaCores);
Vue.component("Modal", Modal);
Vue.component("MensagemItemNaoEncontrado", MensagemItemNaoEncontrado);

export default {
  name: "App",
  store,
  props: {
    hash: String,
  },

  components: {
    Apresentacao,
    CadastroSistema,
    CadastroMenu,
    ListaSistemas,
    ListaMenus,
    ListaItensMenu,
    SelecaoMenuAlteracao,
  },

  computed: {
    verificarItemSalvo() {
      return this.$store.state.itensModulo.itemSalvo;
    },
    exibirMensagem() {
      return store.state.exibirMensagemWebService.exibirMensagem;
    },
    dadosModal() {
      return store.state.dadosModal;
    },
    parametrosMensagem() {
      return JSON.stringify(store.state.exibirMensagemWebService);
    },
    tipoMensagem() {
      return store.state.tipoCrud;
    },
  },

  data() {
    return {
      componente: "Apresentacao",
      dadosSelecao: {},
    };
  },

  methods: {
    retornarPaginaInicial(componente) {
      this.componente = componente;
    },

    classeSelecionada(componente) {
      if (this.componente === componente) {
        return true;
      } else {
        return false;
      }
    },

    componenteSelecionado(componente) {
      this.dadosSelecao = {};
      this.componente = componente;
      this.limpaValores();
    },

    editarSelecao(componente, dadosSelecao) {
      this.componente = componente;
      this.dadosSelecao = dadosSelecao;
      this.$store.commit("validacaoEdicao", true);
    },

    limpaValores() {
      this.$store.commit("validacaoEdicao", false);
      this.$store.commit("edicao", {});
      this.$store.commit("listaFiltrada", []);
      this.$store.commit("listaItens", []);
    },

    CriarEventoFecharMensagem() {
      window.addEventListener("fecharMensagemRetorno", (e) => {
        this.$store.commit("fecharMensagem", e.detail);
      });
    },
  },
  watch: {
    verificarItemSalvo() {
      if (this.verificarItemSalvo) {
        this.componenteSelecionado("ListaItensMenu");
        this.$store.state.itensModulo.itemSalvo = false;
      }
    },
  },
  created() {
    this.CriarEventoFecharMensagem();
  },
};
</script>

<style lang="scss">
@import url("https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.2/css/bulma.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bulma-helpers/0.4.0/css/bulma-helpers.min.css");
@import url("https://marlonemmerich.com/projects/vanilla-navbar/styles.css");
@import "./comum/styles/main.scss";
@import "~@fortawesome/fontawesome-free/css/all.css";

.titulo {
  cursor: pointer;
}
.slide-leave-active,
.slide-enter-active {
  transition: 1.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}
.selecionado {
  background: #c2c8db;
}
.modal-load {
  height: 200px;
  width: 550px !important;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 10;
  z-index: -1;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  .loader {
    height: 180px;
    width: 180px;
  }

  &.is-active {
    opacity: 1;
    z-index: 1;
  }
}
</style>
