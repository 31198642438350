export default class parserRequisicoes {
  constructor() { }
  parserMenu = function (parametros) {
    let parametrosDTO = {}
    parametrosDTO.navbarId = 'navbarId'
    parametrosDTO.customElementShadowRoot = "mf-atena-cadastro-menu"
    parametrosDTO.sistemaId = parametros.sistema.id
    parametrosDTO.descricao = parametros.descricao
    parametrosDTO.backgroundColor = parametros.backgroundColor
    parametrosDTO.color = parametros.color
    parametrosDTO.hoverColor = parametros.hoverColor
    parametrosDTO.customClass = parametros.customClass
    parametrosDTO.logos = [{
      src: "",
      href: "",
      position: "",
      hideOnDevice: "",
      customClass: "",
      clickEvent: "",
      id: "",
      idElement: ""
    }]

    parametrosDTO.logos[0] = {
      src: parametros.logos[0].src,
      href: parametros.logos[0].href,
      position: parametros.logos[0].position,
      hideOnDevice: parametros.logos[0].hideOnDevice,
      customClass: parametros.logos[0].customClass,
      clickEvent: parametros.logos[0].clickEvent,
      id: parametros.logos[0].id,
      idElement: parametros.logos[0].idElement,
    }

    return parametrosDTO
  }

  parserSistema = function (parametros) {
    let parametrosSistema = {}
    parametrosSistema.nome = parametros.nome
    parametrosSistema.descricao = parametros.descricao
    
    return parametrosSistema
  }
}