<template>
    <div class="card column is-12 is-pulled-left has-padding-padding-10">
        <div class="column is-8 is-pulled-left is-marginless is-paddingless has-padding-bottom-10">
            <span>Selecione o menu para listar os itens</span>
            <div class="select is-fullwidth">
                <select
                    v-model="IdMenuSelecionado"
                    @change="preencherListacomItens()">
                    <option value="" disabled selected v-if="IdMenuSelecionado !== ''">Selecione um menu para listar os itens cadastrados.</option>
                    <option v-for="(menu, i) in listaMenus"
                        :key="i"
                        :value="menu.id">
                        {{menu.descricao}}</option>
                </select>
            </div>
        </div>
        <div class="column is-4 is-pulled-left btn-atualizar-Lista">
            <button type="button" class="btnBuscarListaMenus button is-fullwidth" @click="buscarListaMenus()">
            <i  class="fas fa-sync has-padding-right-5" aria-hidden="true"></i>Atualizar Lista</button>
        </div>
        <div id="item" class="card column is-12 is-pulled-left is-marginless">
            <div class="columns is-centered has-margin-top-120" v-if="!listaItens.length && !!IdMenuSelecionado" style="margin:100px; font-size:16px;">
                <p>
                    Este menu não possui itens cadastrados.
                </p>
            </div>
            <div class="columns is-centered has-margin-top-120" v-if="!listaMenus.length && !IdMenuSelecionado" style="margin:100px; font-size:16px">
                <p>
                    Não há menu cadastrado.
                </p>
            </div>
            <div class="columns is-centered has-margin-top-120" v-if="!IdMenuSelecionado" style="margin:100px; font-size:16px">
                <p>
                    Selecione um menu para listar os itens cadastrados.
                </p>
            </div>
            <h2 class="nome" v-if="listaItens !== '' && listaItens.length !== 0">Nome</h2>
            <span id="borda" v-for="(item, i) in listaItens" :key="i">
                <treeVue 
                    class="item" 
                    :item="item"
                    :editar="editarSelecao"
                    :IdMenuSelecionado="IdMenuSelecionado"
                    >
                </treeVue>
            </span>
        </div>
    </div>
</template>

<script>
import treeVue from "@/components/treeVue";

export default {
components: {
    treeVue
},
props:{
    editarSelecao: Function
},
computed: {
    listaItens(){
      return this.$store.state.itensModulo.listaItens
    },
    listaMenus(){
        return this.$store.state.menuModulo.listaMenus
    },
},
data() {
    return {
        IdMenuSelecionado: '',
        listaColunas: [],
        atributosColuna: [],
        tipo:  ''
    }
},
methods: {
    preencherListacomItens() {
        this.$store.dispatch('buscarListaItens', this.IdMenuSelecionado)
    },
    buscarListaMenus() {
        this.$store.dispatch('buscarListaMenu')
    }
},
created() {
    this.buscarListaMenus()
}
};
</script>

<style lang="scss" scoped>
.btn-atualizar-Lista {
    padding-top: 23px;
    padding-right: 0px;
}

#btnEditaMenu {
    position: inherit;
}
#borda { 
  border: solid;
  border-width: 1px 0;
}
.nome{
    padding: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    background-color: #2E4283;
    color:white;
}
#item{
    height:20rem;
    overflow-y: auto;
    border: 1px #dbdbdb solid;
}
</style>