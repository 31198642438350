<template>
  <div class="card column is-12 is-pulled-left">
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit">
        <div class="column is-12 is-pulled-left is-paddingless">
          <ValidationProvider
            class="column is-6 is-pulled-left is-paddingless has-padding-right-10"
            name="Nome"
            rules="required"
          >
            <div slot-scope="{ errors }">
              <span>Digite um nome para o sistema</span>
              <input
                class="input is-small"
                v-model="parametros.nome"
                type="text"
                :class="{
                  'is-danger': errors.length,
                  'margin-bottom': !errors.length,
                }"
              />
              <small class="has-text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider
            class="column is-6 is-pulled-left is-paddingless has-padding-right-10"
            name="Descrição"
            rules="required"
          >
            <div slot-scope="{ errors }">
              <span>Digite uma descrição para o sistema</span>
              <input
                class="input is-small"
                v-model="parametros.descricao"
                type="text"
                :class="{
                  'is-danger': errors.length,
                  'margin-bottom': !errors.length,
                }"
              />
              <small class="has-text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </div>
        <div
          class="column is-12 is-pulled-left is-paddingless has-padding-top-10"
        >
          <div
            class="column is-2 is-pulled-right is-paddingless has-padding-left-10"
          >
            <button class="button is-fullwidth" type="submit">
              <i class="fas fa-save has-padding-right-5" aria-hidden="true"></i
              >Salvar
            </button>
          </div>
          <div class="column is-2 is-pulled-right is-paddingless">
            <button
              type="button"
              class="button is-fullwidth"
              value="Remover"
              @click="exibirModal()"
            >
              <i
                class="fa fa-trash has-padding-right-5 is-size-6"
                aria-hidden="true"
              ></i
              >Deletar
            </button>
          </div>
          <div
            class="column is-2 is-pulled-right is-paddingless has-padding-right-10"
          >
            <button
              type="reset"
              class="button is-fullwidth"
              value="Limpa"
              @click="limpa()"
            >
              <i
                class="btnLimpa fa fa-eraser has-padding-right-5 is-size-5"
                aria-hidden="true"
              ></i
              >Limpar
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <ModalConfirmacao
      :mensagem="this.mensagem"
      :class="exibirModalConfirmacao"
      @modalFechado="exibirModal"
    >
    </ModalConfirmacao>
  </div>
</template>
<script>
import ParserRequisicoes from "@/helpers/parserRequisicoes";
import CadastroSistema from "@/model/sistema/cadastroSistema";
import ModalConfirmacao from "@/components/modal/modalConfirmacao";

export default {
  components: {
    ModalConfirmacao,
  },
  props: {
    dadosSelecao: Object,
  },

  data() {
    return {
      dadoSelecao: this.dadosSelecao,
      parametros: new CadastroSistema(),
      mensagem: "",
      exibirModalConfirmacao: "",
    };
  },
  computed: {
    mensagemWebService() {
      return this.$store.state.exibirMensagemWebService;
    },
  },
  methods: {
    limpa() {
      this.dadoSelecao = {};
      this.parametros = new CadastroSistema();
      this.$refs.form.reset();
    },

    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          let dadosRequisicao = {
            parametros: this.parametros,
            idSistema: this.dadoSelecao.id,
          };
          this.$store.dispatch("publicaSistema", dadosRequisicao);
          this.$emit("abrirModalPesquisa");
          this.limpa();
        }
      });
    },

    newCadastroSistema() {
      this.parametros = new ParserRequisicoes().parserSistema(this.dadoSelecao);
    },

    deletarSistema(id) {
      this.$store.dispatch("deletarSistema", id);
      if (this.mensagemWebService.tipoMensagem !== "erro") this.limpa();
    },

    exibirModal(payload) {
      this.mensagem =
        "Este sistema pode estar sendo utilizado para cadastrar um menu, que também será deletado junto com os seus itens. Deseja realmente continuar?";

      if (this.exibirModalConfirmacao === "") {
        this.exibirModalConfirmacao = "is-active";
      } else {
        this.exibirModalConfirmacao = "";
        this.confirmacaoExclusao = payload;
      }

      if (this.confirmacaoExclusao) {
        this.deletarSistema(this.dadoSelecao.id);
        this.$emit("abrirModalPesquisa");
      }
    },
  },

  created() {
    this.newCadastroSistema();
  },
};
</script>
