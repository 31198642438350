export default class DropDownCadastroItens {
    constructor(objeto) {
        this.text = ""
        this.clickEvent = ""
        this.position = ""
        this.hideOnDevice = ""
        this.customClass = ""
        this.idElement = ""
        this.forceNavbarTop = ""
        this.idPai = ""
        this.idMenu = ""
        Object.assign(this, objeto)
    }

    dropDownCadastroItensProps() {
        return {
            coluna : false,
            dropDown : false,
            customElement : true,
            avatar : false,
            customItem : false
        }
    }
}