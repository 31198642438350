<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="incluirClasseLista">
                    <div class="column is-12 is-pulled-left is-paddingless has-padding-bottom-5">
                        <ValidationProvider name="Nome Classe" rules="required">
                            <div class="column is-10 is-pulled-left is-paddingless has-padding-right-10">
                                <input class="input is-small"
                                    v-model="classeInclusao"
                                    type="text">
                            </div>
                        </ValidationProvider>
                        <div class="column is-2 is-pulled-left is-paddingless has-padding-left-10">
                            <button class="button is-small is-fullwidth"
                                type="submit"
                                :disabled="invalid"
                            >Incluir</button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="tblClasse-prevenir-fechar">
                <table class="table is-striped is-paddingless">
                    <thead>
                        <tr >
                            <th>Nome</th>
                            <th>Remover</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(descricao, i) in listaClassesPrevent" :key="i">
                            <td>{{descricao}}</td>
                            <td>
                                <button 
                                    id="btnRemoverItem"
                                    @click="removerItem(i)"
                                    title="Remover item."
                                    ><i  class="fas fa-minus-circle has-text-danger" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        menuSelecionado: Object,
        classesToPrevent: String,
        listaClasses: Array
    },
    data() {
        return {
            listaClassesPrevent: this.listaClasses,
            classeInclusao: "",
        }
    },
    methods: {
        limpa(){
            this.listaClassesPrevent = ""
        },
        incluirClasseLista() {
            let classeComTrim = this.classeInclusao.replace(/ /g,'')
            if(this.validarClasseExiste(classeComTrim)) {
                alert("Classe já incluída.")
            }else{
                this.listaClassesPrevent.push(classeComTrim)
                this.classeInclusao = ''
                this.$emit("listaClasses", this.listaClassesPrevent)
            }
        },
        removerItem(index) {
            this.listaClassesPrevent.splice(index, 1);
            this.$emit("listaClasses", this.listaClassesPrevent)
        },
        validarClasseExiste(valor) {
            for (let index = 0; index < this.listaClassesPrevent.length; index++) {
                const element = this.listaClassesPrevent[index].toUpperCase();
                if(element === valor.toUpperCase()) {
                    return true
                }
            }
        }
    },
     watch: {
        classesToPrevent: {
            handler() {
                if(this.classesToPrevent){ 
                    this.listaClassesPrevent = this.classesToPrevent.split(',')
                }
                this.$emit("listaClasses", this.listaClassesPrevent)
        },
            deep: true
        },
        menuSelecionado: {
            handler() {
                if(!this.menuSelecionado.id){
                    this.limpa()
                }
            }
        }
     }
}
</script>

<style lang="scss" scoped>
#btnRemoverItem {
    border: none;
    background-color: transparent;
    margin: 2px;
}

#btnRemoverItem:hover {
    border: 1px solid #b5b5b5;
    color: #363636;
    margin: 1px;
    border-radius: 4px;
}

.tblClasse-prevenir-fechar {
    position: relative;
    z-index: 1;
    margin: auto;
    overflow: auto;
    border: 1px #dbdbdb solid;
    height: 200px;
    table {
        min-width: 120px;
        margin: auto;
        border-collapse: separate;
        border-spacing: 0;
        thead {
            th {
                background: rgb(173, 171, 171);
                width: 100%;
                color: #fff !important;
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                font-size: 10px;
                padding: 2px 0 2px 0;
                text-align: center !important;
                border: none !important;
            }
            th:last-child {
                padding-right: 5px;
            }
        }
        td {
            border: none !important;
            text-align: center;
            padding: 0;
            font-size: 12px;
            
        }
    }
}
</style>
