<template>
  <div class="card column is-12 is-pulled-left">
    <filtro tipo="buscarListaMenu"></filtro>
    <div class="column is-12 is-pulled-left is-marginless is-paddingless">
      <div class="table-scroll">
        <table class="table is-fullwidth is-hoverable is-striped">
          <div
            class="columns is-centered"
            v-if="listaMenus.length === 0"
            style="margin:150px; font-size:16px"
          >
            <p>
              Não há menu cadastrado.
            </p>
          </div>
          <thead v-if="listaMenus.length !== 0">
            <tr>
              <th>Sistema</th>
              <th>Descrição</th>
              <th>Menu</th>
              <th class="col-acao has-text-centered">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(itemLista, i) in listaFiltrada" :key="i">
              <td>{{ itemLista.sistema.nome }}</td>
              <td>{{ itemLista.sistema.descricao }}</td>
              <td>{{ itemLista.descricao }}</td>
              <td class="col-acao has-text-centered">
                <acoesTabelaMenu
                  :id="itemLista.id"
                  cadastro="CadastroMenu"
                  :lista="itemLista"
                  :editar-selecao="editarSelecao"
                >
                </acoesTabelaMenu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal v-if="showModal"></modal>
  </div>
</template>

<script>
import filtroGet from "../../components/filtroGet";
import acoesTabelaMenu from "../../components/acoesTabelaMenu.vue";

export default {
  components: {
    filtro: filtroGet,
    acoesTabelaMenu,
  },
  props: {
    editarSelecao: Function,
    arrayPadrao: Array,
    cadastro: String,
    lista: Object,
    id: String,
  },
  data() {
    return {
      idPesquisa: "",
      menuSelecionado: {},
    };
  },
  computed: {
    showModal() {
      return this.$store.state.showModal;
    },
    listaMenus() {
      return this.$store.state.menuModulo.listaMenus;
    },
    listaFiltrada() {
      return this.$store.state.listaFiltrada;
    },
    obterLista() {
      return this.$store.dispatch("buscarListaMenu");
    },
    atualizarTabela() {
      return this.$nextTick(() => {
        this.$store.dispatch("buscarListaMenu");
      });
    },
  },
  mounted() {
    this.obterLista;
  },
  updated() {
    this.atualizarTabela;
  },
};
</script>

<style lang="scss" scoped>
.btn-position {
  position: inherit;
}

.btn-tabela {
  border: none;
  background-color: transparent;
}
i {
  font-size: 14px;
}
.col-acao {
  max-width: 50px !important;
}
</style>
