var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card column is-12 is-pulled-left"},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"column is-12 is-pulled-left is-paddingless"},[_c('ValidationProvider',{staticClass:"column is-6 is-pulled-left is-paddingless has-padding-right-10",attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('span',[_vm._v("Digite um nome para o sistema")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.parametros.nome),expression:"parametros.nome"}],staticClass:"input is-small",class:{
                'is-danger': errors.length,
                'margin-bottom': !errors.length,
              },attrs:{"type":"text"},domProps:{"value":(_vm.parametros.nome)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.parametros, "nome", $event.target.value)}}}),_c('small',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])])}}])}),_c('ValidationProvider',{staticClass:"column is-6 is-pulled-left is-paddingless has-padding-right-10",attrs:{"name":"Descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return _c('div',{},[_c('span',[_vm._v("Digite uma descrição para o sistema")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.parametros.descricao),expression:"parametros.descricao"}],staticClass:"input is-small",class:{
                'is-danger': errors.length,
                'margin-bottom': !errors.length,
              },attrs:{"type":"text"},domProps:{"value":(_vm.parametros.descricao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.parametros, "descricao", $event.target.value)}}}),_c('small',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])])}}])})],1),_c('div',{staticClass:"column is-12 is-pulled-left is-paddingless has-padding-top-10"},[_c('div',{staticClass:"column is-2 is-pulled-right is-paddingless has-padding-left-10"},[_c('button',{staticClass:"button is-fullwidth",attrs:{"type":"submit"}},[_c('i',{staticClass:"fas fa-save has-padding-right-5",attrs:{"aria-hidden":"true"}}),_vm._v("Salvar ")])]),_c('div',{staticClass:"column is-2 is-pulled-right is-paddingless"},[_c('button',{staticClass:"button is-fullwidth",attrs:{"type":"button","value":"Remover"},on:{"click":function($event){return _vm.exibirModal()}}},[_c('i',{staticClass:"fa fa-trash has-padding-right-5 is-size-6",attrs:{"aria-hidden":"true"}}),_vm._v("Deletar ")])]),_c('div',{staticClass:"column is-2 is-pulled-right is-paddingless has-padding-right-10"},[_c('button',{staticClass:"button is-fullwidth",attrs:{"type":"reset","value":"Limpa"},on:{"click":function($event){return _vm.limpa()}}},[_c('i',{staticClass:"btnLimpa fa fa-eraser has-padding-right-5 is-size-5",attrs:{"aria-hidden":"true"}}),_vm._v("Limpar ")])])])])]),_c('ModalConfirmacao',{class:_vm.exibirModalConfirmacao,attrs:{"mensagem":this.mensagem},on:{"modalFechado":_vm.exibirModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }