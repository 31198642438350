<template>
  <ValidationObserver ref="form">
    <div class="card column is-12 is-pulled-left">
      <div class="column is-12 is-pulled-left is-paddingless">
        <div
          class="column is-6 is-pulled-left is-paddingless has-padding-right-10 has-padding-bottom-10"
        >
          <span>Informe o tipo do item</span>
          <div class="select is-fullwidth is-small">
            <select v-model="tipoItem" @change="tipoItemSelecionado()">
              <optgroup
                v-for="(group, name, i) in tiposItens"
                :label="name"
                :key="i"
              >
                <option v-for="(item, i) in group" :key="i" :value="item">
                  {{ item.descricao }}</option
                >
              </optgroup>
            </select>
          </div>
        </div>
        <div
          class="column is-6 is-pulled-left is-paddingless has-padding-left-10 has-padding-bottom-10"
        >
          <span>Selecione o menu para inclusão dos itens</span>
          <div class="select is-fullwidth is-small">
            <select v-model="dadosMenu" @change="menuSelecionado()">
              <option v-for="(menu, i) in listaMenus" :key="i" :value="menu">
                {{ menu.sistema.nome + " - " + menu.descricao }}</option
              >
            </select>
          </div>
        </div>
      </div>
      <component
        v-bind:is="componente"
        :menuSelecionado="dadosMenu"
        :tipoItemSelecionado="dadosTipoItem"
        @salvouObjeto="buscarListaMenus"
        @limpar="limpa"        
      >
      </component>
    </div>
  </ValidationObserver>
</template>

<script>
import CadastroBotao from "./cadastroBotao";
import DropDownCadastro from "./dropDownCadastro";
import DropDownCadastroColunas from "./dropDownCadastroColunas";
import DropDownCadastroItens from "./dropDownCadastroItens";
import CadastroAvatar from "./cadastroAvatar";
import CadastroItemPersonalizado from "./cadastroItemPersonalizado";
import listaItensMenu from "./listaItensMenu";

export default {
  components: {
    CadastroBotao,
    DropDownCadastro,
    DropDownCadastroColunas,
    DropDownCadastroItens,
    CadastroAvatar,
    CadastroItemPersonalizado,
    listaItensMenu,
  },

  props: {
    dadosSelecao: Object,
  },

  computed: {
    listaMenus() {
      return this.$store.state.menuModulo.listaMenus;
    },
  },

  data() {
    return {
      dadosMenu: {},
      dadosTipoItem: "",
      componente: "",
      tipoItem: {},
      valorItem: "",
      parametros: {},
      tiposItens: {
        Itens: [
          { descricao: "Avatar", id: "Avatar", valor: "CadastroAvatar" },
          { descricao: "DropDown", id: "Dropdown", valor: "DropDownCadastro" },
          { descricao: "Item", id: "Item", valor: "CadastroBotao" },
        ],
        SubItens: [
          {
            descricao: "Colunas no DropDown/Avatar",
            id: "Coluna",
            valor: "DropDownCadastroColunas",
          },
          {
            descricao: "Itens no DropDown/Avatar",
            id: "CustomElement",
            valor: "DropDownCadastroItens",
          },
          {
            descricao: "Elemento Personalizado",
            id: "CustomItem",
            valor: "CadastroItemPersonalizado",
          },
        ],
      },
    };
  },

  methods: {   
    limpa() {
      this.tipoItem = {};
      this.dadosMenu = {};
      this.$refs.form.reset();
    },

    tipoItemSelecionado() {
      if (Object.keys(this.dadosMenu).length && this.tipoItem) {
        this.buscarListaMenus();
      }
    },

    menuSelecionado() {
      if (Object.keys(this.dadosMenu).length && this.tipoItem) {
        this.habilitarCamposInclusao();
      }
    },

    habilitarCamposInclusao() {
      this.componente = this.tipoItem.valor;
      this.dadosTipoItem = this.tipoItem.descricao;
    },

    buscarListaMenus() {
      this.$store.dispatch("buscarListaMenu").then(() => {
        for (var i = 0; i < this.listaMenus.length; i++) {
          if (this.listaMenus[i].id === this.dadosMenu.id) {
            this.dadosMenu = this.listaMenus[i];
          }
        }
      });
    },

    filtrarTipoItem(id) {
      let SubItens = this.tiposItens.SubItens.filter((el) => el.id === id);

      return SubItens.length
        ? SubItens
        : this.tiposItens.Itens.filter((el) => el.id === id);
    },

    editarItem() {
      this.tipoItem = this.filtrarTipoItem(this.dadosSelecao.tipoItem)[0];
      this.dadosMenu.id = this.dadosSelecao.idMenu;
      this.menuSelecionado();
    },
  }, 
  created() {
    this.buscarListaMenus();
    this.editarItem();
  },
};
</script>

<style lang="scss" scoped>
.btn-atualizar-Lista {
  margin-top: 12px;
  border: 1px solid rgb(46, 66, 131);
  border-radius: 10px;
}
.select {
  z-index: 0;
}
</style>
