<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div :class="webServiceLoading ? 'modal-webservice-loading' : 'modal-container'">
                    <div v-if="webServiceLoading">
                        <div class="is-pulled-left is-fullwidth has-padding-left-100 pt-5">
                            <i class="fas fa-sync fa-10x animacao-loading" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div v-else>
                        <div class="modal-header">
                            <CabecalhoModal 
                            :fecharModal="fecharModal"/>
                        </div>
                        <div class="modal-body">
                            <previsualizar-menu
                                ref="menu"
                                :dadosMenuSelecionado="dadosMenu">
                            </previsualizar-menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import CabecalhoModal from '@/components/modal/CabecalhoModal.vue'
import PrevisualizarMenu from '@/components/previsualizarMenu'

export default {
    components: {
        CabecalhoModal,
        PrevisualizarMenu
    },
    props: {
        titulo: String,
        dadosMenu: Object
    },
    computed: {
        webServiceLoading() {
            return this.$store.state.webServiceLoading
        }
    },
    methods: {
        fecharModal() {
            this.$store.commit('exibirModal', false)
        },
        CriarKeydownEvent(){
                window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                this.$store.commit('exibirModal', false)
                }
            })
        }
    },
    created() {
         this.CriarKeydownEvent()
    }
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-webservice-loading {
        margin: 0px auto;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        font-family: Helvetica, Arial, sans-serif;
        width: 370px;
        height: 270px;
    }

    .modal-container {
        margin: 0px auto;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        font-family: Helvetica, Arial, sans-serif;
        width: 65%;
        height: 300px;

    }

    .modal-header{
        border-top-left-radius: 5px;
    }

    .modal-body {
        padding: 10px;
    }

    .modal-default-button {
        float: right;
    }

    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

</style>
