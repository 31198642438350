<template>
  <div>
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit">
        <div class="column is-12 is-pulled-left is-paddingless is-marginless">
          <div
            class="column is-6 is-pulled-left is-paddingless has-padding-right-10"
          >
            <ValidationProvider
              class="column is-4 is-pulled-left is-paddingless has-padding-right-10"
              name="Descrição"
              rules="required"
            >
              <div slot-scope="{ errors }">
                <span>Digite uma descrição</span>
                <input
                  class="input is-small"
                  v-model="parametros.text"
                  type="text"
                  :class="{
                    'is-danger': errors.length,
                    'margin-bottom': !errors.length,
                  }"
                />
                <small class="has-text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider
              class="column is-4 is-pulled-left is-paddingless has-padding-right-10 has-padding-left-10"
              name="Posição"
              rules="required"
            >
              <div slot-scope="{ errors }">
                <span>Posição</span>
                <div class="select is-fullwidth is-small">
                  <select
                    v-model="parametros.position"
                    class="input is-small"
                    :class="{
                      'is-danger': errors.length,
                      'margin-bottom': !errors.length,
                    }"
                  >
                    <option
                      v-for="(position, i) in positionOptions"
                      :key="i"
                      :value="position.codigo"
                    >
                      {{ position.descricao }}
                    </option>
                  </select>
                  <small class="has-text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider
              class="column is-4 is-pulled-left is-paddingless has-padding-right-10 has-padding-left-10"
              name="Esconder para"
              rules="required"
            >
              <div slot-scope="{ errors }">
                <span>Esconder para</span>
                <div class="select is-fullwidth is-small">
                  <select
                    v-model="parametros.hideOnDevice"
                    class="input is-small"
                    :class="{
                      'is-danger': errors.length,
                      'margin-bottom': !errors.length,
                    }"
                  >
                    <option
                      v-for="(device, i) in hideOnDevice"
                      :key="i"
                      :value="device.codigo"
                    >
                      {{ device.descricao }}
                    </option>
                  </select>
                  <small class="has-text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </ValidationProvider>
            <div class="column is-12 is-pulled-left is-paddingless">
              <span>Classe personalizada</span>
              <input
                type="text"
                class="input is-small"
                v-model="parametros.customClass"
              />
            </div>
            <div
              class="column is-6 is-pulled-left is-paddingless has-padding-right-10 has-padding-top-15"
            >
              <span>Evento ao clicar</span>
              <input
                type="text"
                class="input is-small"
                v-model="parametros.clickEvent"
              />
            </div>
            <div
              class="column is-6 is-pulled-left is-paddingless has-padding-left-10 has-padding-top-15"
            >
              <span>Id para o elemento</span>
              <input
                type="text"
                class="input is-small"
                v-model="parametros.idElement"
              />
            </div>
            <div
              class="column is-12 is-pulled-left is-paddingless has-padding-top-15"
            >
              <div class="is-pulled-left chkForceNavbarTopDropDown">
                <input type="checkbox" v-model="parametros.forceNavbarTop" />
              </div>
              <div class="is-pulled-left">
                <span>Forçar item a aparecer em baixa resolução</span>
              </div>
            </div>
          </div>
          <div
            class="column is-6 is-pulled-left is-paddingless has-padding-left-10"
          >
            <div>
              <ValidationProvider
                class="column is-9 is-pulled-left is-paddingless"
                rules="required"
                name="Logotipo"
              >
                <div slot-scope="{ errors }">
                  <span
                    >Informe o caminho ou selecione uma imagem para logo</span
                  >
                  <input
                    v-if="nomeArquivo"
                    type="text"
                    :class="{
                      'is-danger': errors.length,
                      'margin-bottom': !errors.length,
                    }"
                    class="input is-small"
                    v-model="nomeArquivo"
                    placeholder="nome arquivo"
                  />
                  <input
                    v-else
                    type="text"
                    :class="{
                      'is-danger': errors.length,
                      'margin-bottom': !errors.length,
                    }"
                    class="input is-small"
                    v-model="parametros.src"
                    placeholder="SRC"
                  />
                  <small class="has-text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
              <file-reader
                class="button is-small btn-file-reader"
                @load="carregarArquivo($event)"
              />
            </div>
            <div class="column is-12 card containerLogotipo">
              <span
                v-if="nomeArquivo === '' && validarBase64(parametros.src)"
                class="is-pulled-left is-size-5 has-padding-30"
                >Não foi selecionada uma imagem para o logotipo</span
              >
              <div v-else>
                <img
                  class="logotipo"
                  :src="`${parametros.src}`"
                  alt="imagem da logo"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
      <div class="column is-2 is-pulled-right is-paddingless">
        <button class="button is-fullwidth" type="button" @click="onSubmit">
          <i class="fas fa-save has-padding-right-5" aria-hidden="true"></i
          >Salvar
        </button>
      </div>
      <DeletarItens @limpar="limpa"></DeletarItens>
      <div
        class="column is-2 is-pulled-right is-paddingless has-padding-right-20"
      >
        <button
          type="reset"
          class="btnLimpa button is-fullwidth"
          value="Limpa"
          @click="limpa()"
        >
          <i
            class="fa fa-eraser has-padding-right-5 is-size-5"
            aria-hidden="true"
          ></i
          >Limpar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/model/itens/cadastroAvatar";
import FileReader from "@/components/importarArquivo";
import DeletarItens from "@/components/deletarItens";

export default {
  components: {
    FileReader,
    DeletarItens,
  },
  props: {
    menuSelecionado: Object,
  },
  data() {
    return {
      parametros: new Avatar(),
      nomeArquivo: "",
      positionOptions: [
        { descricao: "Esquerda", codigo: "left" },
        { descricao: "Centro", codigo: "center" },
        { descricao: "Direita", codigo: "right" },
      ],
      hideOnDevice: [
        { descricao: "Desktop", codigo: "desktop" },
        { descricao: "Mobile", codigo: "mobile" },
      ],
    };
  },
  computed: {
    idItens() {
      return this.$store.state.itensModulo.idItem;
    },
    validacaoEditar() {
      return this.$store.state.editando;
    },
  },
  methods: {
    limpa() {
      this.nomeArquivo = "";
      this.parametros = new Avatar();
      this.$store.commit("edicao", {});
      this.$emit("limpar");
      this.$refs.form.reset();
    },
    validarBase64(str) {
      if (
        str.substring(0, 15) === "data:image/jpeg" ||
        str.substring(0, 14) === "data:image/png" ||
        str.substring(0, 14) === "data:image/jpg"
      ) {
        this.nomeArquivo = "Carregado arquivo base64.";
        return false;
      }
      return true;
    },
    carregarArquivo(param) {
      this.parametros.src = param.file;
      this.nomeArquivo = param.name;
    },
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.parametros.idMenu = this.menuSelecionado.id;
          let payload = {
            ...this.parametros,
            ...this.parametros.avatarProps(),
            parametros: this.parametros,
            idItem: this.idItens.id,
          };
          this.$store.dispatch("publicarIten", payload);
          this.parametros = new Avatar();
          this.nomeArquivo = "";
          this.$emit("salvouObjeto");
          this.limpa();
          this.$store.dispatch("redicionarModalPesquisa");
        }
      });
    },
    valorDeEntradaEdicao() {
      if (
        this.menuSelecionado &&
        this.idItens !== {} &&
          this.idItens.src !== undefined &&
          this.validacaoEditar
      ) {
        Object.assign(this.parametros, this.idItens);
      }
    },
  },
  created() {
    this.valorDeEntradaEdicao();
  },
};
</script>

<style lang="scss" scoped>
.containerLogotipo {
  align-content: center;
  width: 100%;
  text-align: center;
  height: 170px;
  float: left;
}

.logotipo {
  width: auto;
  height: 140px;
  margin: auto;
}

.chkForceNavbarTopDropDown {
  padding-top: 2px;
  padding-right: 5px;
}
</style>
