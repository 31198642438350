import axios from 'axios'
import config from 'config'

function buscarListaMenu(commit, dispatch) {
    commit("exibirOcultarModalLoading", true)
    
    return axios(`${config.apiUrl}/menu`)
        .then(function (response) {
            commit('listaMenus', response.data)
            commit('listaFiltrada', response.data)
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            commit("exibirOcultarModalLoading", false)
        })

}

function buscarMenu(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    return axios(`${config.apiUrl}/menu/${payload}`)
        .then(function (response) {
            delete response.data.customElements

            commit('menuId', response.data)
            commit('exibirModal', true)
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            commit("exibirOcultarModalLoading", false)
        })
}

function publicaMenu(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    let parametroEndPoint = payload.idMenu === undefined ? 'menu' : 'menu/' + payload.idMenu
    const requestOptions = {
        method: payload.idMenu === undefined ? 'post' : 'put',
        data: JSON.stringify(payload.parametros),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data) {
            return data;
        }]
    }
    return axios(`${config.apiUrl}/${parametroEndPoint}`,requestOptions)
        .then(function (response) {
            dispatch('montarMensagemSucesso')
            commit('tipoCrud', response.config.method)
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            commit("exibirOcultarModalLoading", false)
        })
}

function deletarMenu(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: 'delete',
        data: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data) {
            return data;
        }]
    }
    return axios(`${config.apiUrl}/menu/${payload}/true`,requestOptions)
        .then(function (response) {
            dispatch('montarMensagemSucesso')
            commit('tipoCrud', response.config.method)
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            commit("exibirOcultarModalLoading", false)
        })
}

export const MenuService = {
    buscarListaMenu,
    buscarMenu,
    publicaMenu,
    deletarMenu
}
