<template>
<div>
  <div class="itens" :class="{bold: ItensFilhos}" @click="alterna">
    <span v-if="ItensFilhos">[{{ abrir ? '-' : '+' }}]</span>
    {{item.text}} ( {{ tipoItem(item)}} )
    <button 
      id="btnEditaMenu"
      class="button is-small"
      @click="prepararEdicao(item)"
      ><i class="fas fa-edit fa-sm" aria-hidden="true"></i>
    </button>
  </div>  
  <span v-show="abrir" v-if="ItensFilhos">
    <treeVue 
      class="item"
      v-for="(itensFilhos, i) in item.itensFilhos" :key="i"
      :item="itensFilhos"
      :IdMenuSelecionado="IdMenuSelecionado"
      :editar="editar">
    </treeVue>
  </span>
</div>
</template>

<script>

export default {
  name: "treeVue",
  props: {
    item: Object,
    editar: Function,
    IdMenuSelecionado: String
  },
  data() {
    return {
      abrir: false,
    }
  },
  computed: {
    ItensFilhos() {
      return this.item.itensFilhos.length;
    },
  },
  methods: {
    alterna() {
      if (this.ItensFilhos) {
          this.abrir = !this.abrir;
      }
    },

    prepararEdicao(item){
      let payload = {
            edicao: true,
            dadosSelecao: item,
            idMenu: this.IdMenuSelecionado,
            tipoItem: this.tipoItem(item)
        }
        this.editar('SelecaoMenuAlteracao', payload)
        this.$store.commit("edicao", this.item)
    },

    tipoItem(item) {
      let valorCampo = "";
      valorCampo = this.itemPreenchido(valorCampo, "Avatar", item.avatar);
      valorCampo = this.itemPreenchido(valorCampo, "Dropdown", item.dropDown);
      valorCampo = this.itemPreenchido(valorCampo, "Coluna", item.coluna);
      valorCampo = this.itemPreenchido(valorCampo, "CustomElement", item.customElement);
      valorCampo = this.itemPreenchido(valorCampo, "CustomItem", item.customItem);

      return valorCampo ? valorCampo : "Item"
    },

    itemPreenchido(valorCampo, identificacao, propriedade){
        if (valorCampo === "" && propriedade){
          valorCampo = identificacao;
        }
        return valorCampo;
    },
  }
}
</script>

<style scoped>
body {
  font-family: Menlo, Consolas, monospace;
  color: #444;
}
.item {
  cursor: pointer;
  padding-left: 10px;
}
.bold {
  font-weight: bold;
}
ul {
  padding-left: 1em;
  line-height: 1.5em;
  list-style-type: dot;
}
#btnEditaMenu{
  margin-bottom: 2px;
  border-radius: none;
  border: none;
  padding-bottom: 10px;
}
</style>