<template>
  <div>
    <label id="carregarArquivo">
      <input
        type="file"
        style="display: none"
        ref="fileInput"
        accept=".jpg,.jpeg,.png"
        @change="loadTextFromFile"/>
        <i  class="fas fa-images has-padding-right-5" aria-hidden="true"></i>
        <strong>Imagem</strong></label>
  </div>
</template>

<script>
export default {
  methods: {
    loadTextFromFile(ev) {

      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = e => {
        let fileAndName = {
          file: e.target.result,
          name: file.name 
        }
        this.$emit("load", fileAndName);
      }
      reader.readAsDataURL(file);
    }
  }
};
</script>

<style lang="scss" scoped>

  #carregarArquivo {
    cursor: pointer;
  }

  input {
    font-size: 0.8rem !important;
    text-align: center !important;
    
  }

</style>