import { MenuService } from '@/services/menu.servico'

export const menuModulo = {
  state: {
    listaMenus: [],
    menuId:{},
  },
  mutations: {
    listaMenus(state,payload) {
      state.listaMenus = payload
    },
    menuId(state,payload) {
      state.menuId = payload
    }
  },
  
  actions: {
    buscarListaMenu({commit, dispatch}) {
      MenuService.buscarListaMenu(commit, dispatch) 
    },

    buscarMenu({commit, dispatch}, payload) {
      MenuService.buscarMenu(commit, dispatch, payload)
    },

    publicaMenu({commit, dispatch}, payload) {
      MenuService.publicaMenu(commit, dispatch, payload)
    },

    deletarMenu({commit, dispatch}, payload) {
      MenuService.deletarMenu(commit, dispatch, payload)
    }
  }
}