<template>
    <div>
        <ValidationObserver ref="form">
            <form @submit.prevent="onSubmit">
                <div class="column is-6 is-pulled-left is-paddingless has-padding-top-5">
                    <div class="column is-12 is-pulled-left is-paddingless has-padding-right-10">
                        <ValidationProvider class="column is-6 is-pulled-left is-paddingless has-padding-right-10" name="Descrição" rules="required">
                            <div slot-scope="{ errors }">
                                <span>Digite uma descrição</span>
                                <input class="input is-small"
                                    v-model="parametros.text"
                                    type="text" 
                                    :class="{'is-danger' : errors.length, 'margin-bottom': !errors.length}">
                                <small class="has-text-danger">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <div class="column is-6 is-pulled-left is-paddingless has-padding-left-10">
                            <span>Evento ao clicar</span>
                            <input type="text"
                                class="input is-small"
                                v-model="parametros.clickEvent">
                        </div>
                    </div>
                    <div class="column is-12 is-pulled-left is-paddingless has-padding-right-10 ">
                        <div class="column is-6 is-pulled-left is-paddingless has-padding-right-10">
                            <span>Rota ao clicar</span>
                            <input type="text"
                                class="input is-small"
                                v-model="parametros.href">
                        </div>
                        <div class="column is-6 is-pulled-left is-paddingless has-padding-left-10">
                            <span>Id para o elemento</span>
                            <input type="text"
                                class="input is-small"
                                v-model="parametros.idElement">
                        </div>
                    </div>
                    <div class="column is-12 is-pulled-left is-paddingless has-padding-right-10">
                        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-10">
                            <span>Classe personalizada</span>
                            <input type="text"
                                class="input is-small"
                                v-model="parametros.customClass">
                        </div>
                        <div class="column is-6 is-pulled-left is-paddingless has-padding-right-10 has-padding-top-10">
                            <span>Esconder para</span>
                            <div class="select is-fullwidth is-small">
                                <select v-model="parametros.hideOnDevice" 
                                    class="input is-small">   
                                    <option v-for="(device, i) in hideOnDevice"
                                        :key="i"
                                        :value="device.codigo">
                                        {{device.descricao}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <ValidationProvider class="column is-6 is-pulled-left is-paddingless has-padding-left-10 has-padding-top-10" name="Posição" rules="required">
                            <div slot-scope="{ errors }">
                                <span>Posição</span>
                                <div class="select is-fullwidth is-small">
                                    <select v-model="parametros.position" 
                                        class="input is-small"
                                        :class="{'is-danger' : errors.length, 'margin-bottom': !errors.length}">   
                                        <option v-for="(position, i) in positionOptions"
                                            :key="i"
                                            :value="position.codigo">
                                            {{position.descricao}}
                                        </option>
                                    </select>
                                    <small class="has-text-danger">{{ errors[0] }}</small>
                                </div>
                            </div>
                        </ValidationProvider>
                        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
                            <div class="is-pulled-left chkForceNavbarTopDropDown">
                                <input type="checkbox"
                                    v-model="parametros.forceNavbarTop">
                            </div>
                            <div class="is-pulled-left">
                                <span>Forçar item a aparecer em baixa resolução</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
        <div class="column is-6 is-pulled-left is-paddingless">
            <div class="column is-12 is-pulled-left is-paddingless">
                <span>Classe para prevenir fechar ao clicar</span>
                <TabelaClasses class="tbl-container"
                    :menuSelecionado="this.menuSelecionado"
                    :classesToPrevent="this.classesToPreventCloseOnClick"
                    :listaClasses="listaClasses"
                    @listaClasses="parametros.classesToPreventCloseOnClick = $event"/>
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-20">
            <div class="column is-2 is-pulled-right is-paddingless">
                <button type="button" class="button is-fullwidth" @click="onSubmit">
                    <i  class="fas fa-save has-padding-right-5" aria-hidden="true"></i>Salvar
                </button>
            </div>
            <DeletarItens @limpar="limpa"></DeletarItens>
            <div class="column is-2 is-pulled-right is-paddingless has-padding-right-20">
                <button type="reset" class="btnLimpa button is-fullwidth" value="Limpa" @click="limpa()">
                <i  class="fa fa-eraser has-padding-right-5 is-size-5" aria-hidden="true"></i>Limpar</button>
            </div>
        </div>
    </div>
</template>

<script>
import DropDownCadastro from '@/model/itens/dropDownCadastro'
import TabelaClasses  from '@/utils/tabela'
import DeletarItens from "@/components/deletarItens"

export default {
    components: {
        TabelaClasses,
        DeletarItens
    },
    props: {
        menuSelecionado: Object
    },
    computed: {
        idItens() {
            return this.$store.state.itensModulo.idItem
        },
    },
    data() {
        return {
            classesToPreventCloseOnClick:"",
            listaClasses: [],
            tipoItem: "",
            heranca: "",
            idItemMenuSelecionada: "",
            parametros: new DropDownCadastro(),
            positionOptions: [
                {descricao: "Esquerda", codigo: "left"},
                {descricao: "Centro", codigo: "center"},
                {descricao: "Direita", codigo: "right"} 
            ],
            hideOnDevice: [
                {descricao: "Desktop", codigo: "desktop"},
                {descricao: "Mobile", codigo: "mobile"}
            ]
        }
    },
    methods: {
        limpa(){
            this.parametros = new DropDownCadastro()
            this.$store.commit("edicao", {})
            this.$emit('limpar')
            this.$refs.form.reset()
        },
        onSubmit() {
            this.$refs.form.validate().then(success => {
                if(success){
                    this.parametros.idMenu = this.menuSelecionado.id
                    let payload = {
                        ...this.parametros,
                        ...this.parametros.dropDownCadastroProps(),
                        parametros: this.parametros,
                        idItem: this.idItens.id
                    }
                    this.$store.dispatch('publicarIten', payload);
                    this.listaClasses = []
                    this.parametros = new DropDownCadastro()
                    this.$emit('salvouObjeto')
                    this.limpa()                    
                    this.$store.dispatch("redicionarModalPesquisa", true);
                }
            })
        }
    },
    watch: {
        menuSelecionado: {
            handler() {
                if(this.idItens.avatar !== undefined){ 
                    Object.assign(this.parametros, this.idItens);
                    this.classesToPreventCloseOnClick = this.idItens.classesToPreventCloseOnClick
                }
            },
            deep: true
        }
    },
    created() {
        this.$store.dispatch('buscarListaMenu')
    }

}
</script>

<style lang="scss" scoped>
    .chkForceNavbarTopDropDown {
        padding-top: 2px;
        padding-right: 5px;
    };

    td {
        font-size: 12px !important;
    };

    .tbl-container {
        border: solid 1px rgb(216, 216, 216);
        height: 250px;
        padding: 5px;
        border-radius: 3px;
    };
</style>
