import axios from 'axios'
import config from 'config'

function buscarListaItens(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

        return axios(`${config.apiUrl}/item/menu/${payload}`)
        .then(function (response) {
            commit('listaItens', response.data)
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            commit("exibirOcultarModalLoading", false)
        })
}

function publicarIten(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    let parametroEndPoint = payload.idItem === undefined ? 'item': 'item/' + payload.idItem
    const requestOptions = {
        method: payload.idItem === undefined ? 'post' : 'put',
        data: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data) {
            return data;
        }]
    }
    return axios(`${config.apiUrl}/${parametroEndPoint}`,requestOptions)
        .then(function (response) {
            dispatch('montarMensagemSucesso')
            commit('tipoCrud', response.config.method)
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            commit("exibirOcultarModalLoading", false)
        })
}

function deletarItens(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: 'delete',
        data: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data) {
            return data;
        }]
    }
    return axios(`${config.apiUrl}/item/excluir_item/${payload}`,requestOptions)
        .then(function (response) {
            dispatch('montarMensagemSucesso')
            commit('tipoCrud', response.config.method)
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            commit("exibirOcultarModalLoading", false)
        })
}

export const ItensService = {
    buscarListaItens,
    publicarIten,
    deletarItens
}
