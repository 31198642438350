<template>
  <div>
    <div
      class="column is-12 is-pulled-left"
      v-if="!Object.keys(this.dropDownItems).length && !idItemMenuSelecionada===''"
    >
      <MensagemItemNaoEncontrado :tipoItemSelecionado="tipoItemSelecionado" />
    </div>
    <div v-else>
      <ValidationObserver ref="form">
        <form @submit.prevent="onSubmit">
          <div class="column is-12 is-pulled-left is-paddingless">
            <ValidationProvider
              class="column is-6 is-pulled-left is-paddingless has-padding-right-10"
              name="Item para inclusão"
              rules="required"
            >
              <div slot-scope="{ errors }">
                <span>Informe o item do menu para inclusão</span>
                <div class="select is-fullwidth is-small">
                  <select
                    v-model="idItemMenuSelecionada"
                    class="input is-small"
                    :class="{
                      'is-danger': errors.length,
                      'margin-bottom': !errors.length,
                    }"
                  >
                    <option
                      v-for="(dropDown, i) in dropDownItems"
                      :key="i"
                      :value="dropDown.id"
                    >
                      {{
                        dropDown.text
                          ? dropDown.text + " - " + dropDown.id
                          : dropDown.id
                      }}
                    </option>
                  </select>
                  <small class="has-text-danger">{{ errors[0] }}</small>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider
              class="column is-6 is-pulled-left is-paddingless"
              name="Descrição"
              rules="required"
            >
              <div slot-scope="{ errors }">
                <span>Digite uma descrição</span>
                <input
                  class="input is-small"
                  v-model="parametros.text"
                  type="text"
                  :class="{
                    'is-danger': errors.length,
                    'margin-bottom': !errors.length,
                  }"
                />
                <small class="has-text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
          </div>
        </form>
      </ValidationObserver>
      <div
        class="column is-12 is-pulled-left is-paddingless has-padding-top-20"
      >
        <div class="column is-2 is-pulled-right is-paddingless">
          <button type="button" class="button is-fullwidth" @click="onSubmit">
            <i class="fas fa-save has-padding-right-5" aria-hidden="true"></i
            >Salvar
          </button>
        </div>
        <DeletarItens @limpar="limpa"></DeletarItens>
        <div
          class="column is-2 is-pulled-right is-paddingless has-padding-right-20"
        >
          <button
            type="reset"
            class="btnLimpa button is-fullwidth"
            value="Limpa"
            @click="limpa()"
          >
            <i
              class="fa fa-eraser has-padding-right-5 is-size-5"
              aria-hidden="true"
            ></i
            >Limpar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropDownCadastroColunas from "@/model/itens/dropDownCadastroColunas";
import DeletarItens from "@/components/deletarItens";
import MensagemItemNaoEncontrado from "@/utils/mensagemItemNaoEncontrado";

export default {
  props: {
    menuSelecionado: Object,
    tipoItemSelecionado: String,
  },
  components: {
    DeletarItens,
    MensagemItemNaoEncontrado,
  },
  computed: {
    dropDownItems() {
      return this.somenteDropDowns();
    },
    idItens() {
      return this.$store.state.itensModulo.idItem;
    },
  },
  data() {
    return {
      idItemMenuSelecionada: "",
      parametros: new DropDownCadastroColunas(),
      itemMenu: {},
    };
  },
  methods: {
    limpa() {
      this.parametros = new DropDownCadastroColunas();
      this.$store.commit("edicao", {});
      this.$emit("limpar");
      this.$refs.form.reset();
    },

    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.parametros.idPai = this.idItemMenuSelecionada;
          this.parametros.idMenu = this.menuSelecionado.id;
          let payload = {
            ...this.parametros,
            ...this.parametros.dropDownCadastroColunasProps(),
            idItem: this.idItens.id,
          };
          this.$store.dispatch("publicarIten", payload);
          this.idItemMenuSelecionada = "";
          this.parametros = new DropDownCadastroColunas();
          this.$emit("salvouObjeto");
          this.limpa();          
          this.$store.dispatch("redicionarModalPesquisa");
        }
      });
    },

    somenteDropDowns() {
      let somenteDropDowns = [];
      let arrayItems = this.menuSelecionado.itens
        ? this.menuSelecionado.itens
        : [];
      for (let index = 0; index < arrayItems.length; index++) {
        const element = arrayItems[index];
        if (element.dropDown === true || element.avatar === true) {
          somenteDropDowns.push(element);
        }
      }
      return somenteDropDowns;
    },

    valorDeEntradaEdicao() {
      if (this.menuSelecionado && this.idItens !== {}) {
        Object.assign(this.parametros, this.idItens);
        this.idItemMenuSelecionada = this.idItens.idPai;
      }
    },
  },

  created() {
    this.valorDeEntradaEdicao();
  },
};
</script>
