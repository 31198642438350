<template>
  <div>
    <div
      class="column is-12 is-pulled-left"
      v-if="
        !Object.keys(this.dropDownItems).length && !dropDownSelecionada === ''
      "
    >
      <MensagemItemNaoEncontrado :tipoItemSelecionado="tipoItemSelecionado" />
    </div>
    <div v-else>
      <ValidationObserver ref="form">
        <form @submit.prevent="onSubmit">
          <ValidationProvider
            v-if="!itensMenus"
            class="column is-6 is-pulled-left is-paddingless has-padding-right-10"
            name="Item para inclusão"
            rules="required"
          >
            <div slot-scope="{ errors }">
              <span>Informe o item do menu para inclusão</span>
              <div class="select is-fullwidth is-small">
                <select
                  v-model="dropDownSelecionada"
                  class="input is-small"
                  :class="{
                    'is-danger': errors.length,
                    'margin-bottom': !errors.length,
                  }"
                  @change="preencherListaColunas"
                >
                  <option
                    v-for="(dropDown, i) in dropDownItems"
                    :key="i"
                    :value="dropDown"
                  >
                    {{ dropDown.text }}
                  </option>
                </select>
                <small class="has-text-danger">{{ errors[0] }}</small>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            v-if="!itensMenus"
            class="column is-6 is-pulled-left is-paddingless has-padding-left-10"
            name="Coluna para inclusão"
            rules="required"
          >
            <div slot-scope="{ errors }">
              <span>Informe a coluna para inclusão do item</span>
              <div class="select is-fullwidth is-small">
                <select
                  v-model="parametros.idPai"
                  class="input is-small"
                  :class="{
                    'is-danger': errors.length,
                    'margin-bottom': !errors.length,
                  }"
                >
                  <option
                    v-if="
                      !Object.keys(itensFilhosLista).length &&
                        dropDownSelecionada
                    "
                    value=""
                    disabled
                    selected
                    >Sem Colunas Cadastradas para inclusão do item.</option
                  >
                  <option
                    v-for="(dropDown, i) in itensFilhosLista"
                    :key="i"
                    :value="dropDown.id"
                  >
                    {{ dropDown.text }}
                  </option>
                </select>
                <small class="has-text-danger">{{ errors[0] }}</small>
              </div>
            </div>
          </ValidationProvider>
          <div
            class="column is-12 is-pulled-left is-paddingless has-padding-top-15"
          >
            <div class="column is-6 is-pulled-left is-paddingless">
              <span>Id para o elemento</span>
              <input
                type="text"
                class="input is-small"
                v-model="parametros.idElement"
              />
            </div>
          </div>
          <div
            class="column is-12 is-pulled-left is-paddingless has-padding-top-15"
          >
            <span>Elemento em HTML</span>
            <div class="column is-12 is-paddingless">
              <div class="control">
                <textarea
                  rows="10"
                  class="textarea is-small has-fixed-size"
                  placeholder="Informe o elemento em HTML."
                  v-model="parametros.html"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div
        class="column is-12 is-pulled-left is-paddingless has-padding-top-20"
      >
        <div class="column is-2 is-pulled-right is-paddingless">
          <button type="button" class="button is-fullwidth" @click="onSubmit">
            <i class="fas fa-save has-padding-right-5" aria-hidden="true"></i
            >Salvar
          </button>
        </div>
        <DeletarItens @limpar="limpa"></DeletarItens>
        <div
          class="column is-2 is-pulled-right is-paddingless has-padding-right-20"
        >
          <button
            type="reset"
            class="btnLimpa button is-fullwidth"
            value="Limpa"
            @click="limpa()"
          >
            <i
              class="fa fa-eraser has-padding-right-5 is-size-5"
              aria-hidden="true"
            ></i
            >Limpar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CadastroItemPersonalizado from "@/model/itens/cadastroItemPersonalizado";
import DeletarItens from "@/components/deletarItens";
import MensagemItemNaoEncontrado from "@/utils/mensagemItemNaoEncontrado";

export default {
  props: {
    menuSelecionado: Object,
    tipoItemSelecionado: String,
  },
  components: {
    MensagemItemNaoEncontrado,
    DeletarItens,
  },
  computed: {
    dropDownItems() {
      return this.somenteDropDowns();
    },
    itensFilhosLista() {
      return this.preencherListaColunas();
    },
    idItens() {
      return this.$store.state.itensModulo.idItem;
    },
    itensMenus() {
      return this.$store.state.editando;
    },
  },
  data() {
    return {
      dropDownSelecionada: "",
      parametros: new CadastroItemPersonalizado(),
    };
  },
  methods: {
    limpa() {
      this.parametros = new CadastroItemPersonalizado();
      this.$store.commit("edicao", {});
      this.$emit("limpar");
      this.$refs.form.reset();
    },

    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.parametros.idMenu = this.menuSelecionado.id;
          let payload = {
            ...this.parametros,
            ...this.parametros.cadastroItemPersonalizadoProps(),
            parametros: this.parametros,
            idItem: this.idItens.id,
          };
          this.$store.dispatch("publicarIten", payload);
          this.dropDownSelecionada = "";
          this.parametros = new CadastroItemPersonalizado();
          this.$emit("salvouObjeto");
          this.limpa();
          this.$store.dispatch("redicionarModalPesquisa");
        }
      });
    },

    somenteDropDowns() {
      let somenteDropDowns = [];
      let arrayItems = this.menuSelecionado.itens
        ? this.menuSelecionado.itens
        : [];
      for (let index = 0; index < arrayItems.length; index++) {
        const element = arrayItems[index];
        if (element.dropDown === true || element.avatar === true) {
          somenteDropDowns.push(element);
        }
      }
      return somenteDropDowns;
    },

    preencherListaColunas() {
      let listaColunas = [];
      let arrayItems = this.dropDownSelecionada.itensFilhos
        ? this.dropDownSelecionada.itensFilhos
        : [];
      for (let index = 0; index < arrayItems.length; index++) {
        const element = arrayItems[index];
        listaColunas.push(element);
      }
      return listaColunas;
    },

    valorDeEntradaEdicao() {
      if (this.menuSelecionado && this.idItens !== {}) {
        Object.assign(this.parametros, this.idItens);
      }
    },
  },

  created() {
    this.valorDeEntradaEdicao();
  },
};
</script>
