export default class Avatar {

    constructor(objeto) {
        this.text = ''
        this.clickEvent = ''
        this.position = ''
        this.hideOnDevice = ''
        this.customClass = ''
        this.idElement = ''
        this.forceNavbarTop = ''
        this.src = ''
        this.idMenu = ''
        Object.assign(this, objeto);
    }

    avatarProps() {
        return {
            coluna: false,
            dropDown: false,
            customElement: false,
            avatar: true,
            customItem: false,
        }
    }
}