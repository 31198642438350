import Vue from 'vue'
import Vuex from 'vuex'
import storeConfig from '@/store/storeConfig'
import { itensModulo }  from '@/store/itens.modulo'
import { menuModulo } from '@/store/menu.modulo'
import { sistemaModulo } from '@/store/sistema.modulo'


Vue.use(Vuex)

const store = new Vuex.Store({
  ...storeConfig,
  modules: {
    itensModulo,
    menuModulo,
    sistemaModulo
  }
})

export default store