<template>
    <div class="cardPersonalizado column is-12 is-pulled-left is-paddingless">
        <div class="has-margin-bottom-15">
            <span class="is-size-3 has-text-weight-medium">
                MS-Cadastro menu
            </span>
            <p>
                Módulo criado para que seja possível consultar e criar diferentes templates para o Vanilla Menu.
            </p>
        </div>
        <div class="template">
        <TemplatePreview>
            <div class="menu">
                <img :src="logoAtena" alt="Logo Atena" class="img-logoMenu">
                <span class="btnAlteraDropdown item" @click="alteraDropdown($event)">Itens/Item</span>
                <div class="dropdown">
                    <div class="dropdown__header" 
                        @click="alteraDropdown($event, 'drop1')"
                        @focusout="dropDownAtivo = ''"
                        tabindex="0"
                        :class="{'is-active': dropDownAtivo === 'drop1'}">
                        <span class="item">Itens/DropDown</span> 
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                    </div>
                    <div class="dropdown__content">
                        <ul>
                            <li class="itens">SubItens / Coluna</li>
                            <li class="subItens">SubItens / Item no DropDown</li>
                        </ul>
                    </div>
                </div>
                <div class="dropdown">
                    <div class="dropdown__header" 
                        @click="alteraDropdown($event, 'drop2')"
                        @focusout="dropDownAtivo = ''"
                        tabindex="0"
                        :class="{'is-active': dropDownAtivo === 'drop2'}">
                        <span class="item">Itens/DropDown</span> 
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                    </div>
                    <div class="dropdown__content">
                        <ul>
                            <li class="itens">SubItens / Coluna</li>
                            <li class="subItens personalizado">SubItens / Item Personalizado</li>
                        </ul>
                    </div>
                </div>
                <div class="dropdown">
                    <div class="dropdown__header" 
                        @click="alteraDropdown($event, 'drop3')"
                        @focusout="dropDownAtivo = ''"
                        tabindex="0"
                        :class="{'is-active': dropDownAtivo === 'drop3'}">
                        <img :src="avatarImg" alt="Logo Avatar" class="img-avatar"> 
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                    </div>
                        <div class="dropdown__content">
                            <ul>
                                <li class="itens">SubItens / Coluna</li>
                                <li class="subItens">SubItens / Item no Avatar</li>
                            </ul>
                        </div>
                    </div>
            </div>
        </TemplatePreview>
        </div>
        <div class="column is-12 has-margin-top-15 is-pulled-left">
            <div class="columns is-vcentered">
                <div class="has-padding-right-15">
                    <p>Documentação do navbar-vanilla disponível em:</p>
                </div>
                <div>
                    <span class="menu-doc"><a href="https://github.com/marlonemmerich/vanilla-navbar">https://github.com/marlonemmerich/vanilla-navbar</a></span>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import TemplatePreview from '@/components/templatePreview'
import { logoAtena, avatarImg } from "@/assets/base64Img"

export default {
    components: {
        TemplatePreview
    },
    data ( )  { 
        return  { 
            dropDownAtivo: ''
        } 
    },
    computed: {
        logoAtena() {
            return logoAtena
        },
        avatarImg() {
            return avatarImg
        }
    },
    methods: {
        alteraDropdown (event, item) {
            event.currentTarget.classList.toggle('is-active')
            this.dropDownAtivo = item
        },
    } 
};
</script>

<style lang="scss" scoped>
@import '@/comum/styles/apresentacao'
</style>