export default class CadastroItemPersonalizado {
    constructor(objeto) {
        this.idElement = ""
        this.html = `<div class='column is-4 is-pulled-left has-background-primary'><span>Teste de Elemento HTML</span></div>`
        this.idPai = ""
        this.text = ""
        this.idMenu = ""
        Object.assign(this, objeto);
    }

    cadastroItemPersonalizadoProps() {
        return {
            coluna: false,
            dropDown: false,
            customElement: false,
            avatar: false,
            customItem: true
        }
    }
}