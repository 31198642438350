<template>
  <div>
    <div
      v-if="this.botaoDeletar"
      class="column is-2 is-pulled-right is-paddingless has-padding-right-20"
    >
      <button
        type="button"
        class="btnExibirModal button is-fullwidth"
        value="Remover"
        @click="exibirModal()"
      >
        <i
          class="fa fa-trash has-padding-right-5 is-size-6"
          aria-hidden="true"
        ></i
        >Deletar
      </button>
    </div>
    <modal-confirmacao
      :mensagem="this.mensagem"
      :class="exibirModalConfirmacao"
      @modalFechado="exibirModal"
    >
    </modal-confirmacao>
  </div>
</template>

<script>
import ModalConfirmacao from "@/components/modal/modalConfirmacao";

export default {
  components: {
    ModalConfirmacao,
  },
  data() {
    return {
      mensagem: "",
      exibirModalConfirmacao: "",
      confirmacaoExclusao: false,
    };
  },
  computed: {
    idItens() {
      return this.$store.state.itensModulo.idItem;
    },
    botaoDeletar() {
      return this.$store.state.editando;
    },
  },
  methods: {
    exibirModal(payload) {
      this.mensagem =
        this.idItens.itensFilhos.length > 0
          ? "Este item possui sub-itens cadastrados que serão excluidos junto, deseja realmente continuar?"
          : "Deseja realmente excluir este item?";
      if (this.exibirModalConfirmacao === "") {
        this.exibirModalConfirmacao = "is-active";
      } else {
        this.exibirModalConfirmacao = "";
        this.confirmacaoExclusao = payload;
      }
      if (this.confirmacaoExclusao) {
        this.deletarItem(this.idItens.id);
      }
    },
    deletarItem(id) {
      this.$store.dispatch("deletarItens", id);
      this.$emit("limpar");
      this.$store.dispatch("redicionarModalPesquisa");
    },
  },
};
</script>
