<template>
  <div>
      <span>Preview</span>
      <span id="btnfecharModal" @click="fecharModal" class="fechar">X</span>
  </div>
</template>

<script>
export default {
    props: {
        titulo: String,
        fecharModal: Function
    }
}
</script>

<style scoped>
    input {
        font-size: 0.8rem !important;
        text-align: center !important;
    }


    div {
        background-color: #2e4283;
        padding: 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    Span {
        font-weight: bold;
        font-size: 20px;
        color: white;

    }

    .fechar {
        margin-top: 3px;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(16, 135, 247, 0.815);
        cursor: pointer;
        border-radius: 50%;
        width: 23px;
        height: 23px;
    }

    .fechar:hover {
        transform: scale(1.2)
    }

</style>