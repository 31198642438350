import { SistemaService } from '@/services/sistema.servico'

export const sistemaModulo = {
    state: {
        listaSistemas: [],
    },
      mutations: {
        listaSistemas(state,payload) {
          state.listaSistemas = payload
        },
    },
      
    actions: {
        buscarSistema({commit, dispatch}, payload) {
            SistemaService.buscarSistema(commit, dispatch, payload)
        },

        buscarListaSistemas({commit, dispatch}) {
            SistemaService.buscarListaSistemas(commit, dispatch)
        },

        publicaSistema({commit, dispatch}, payload) {
            SistemaService.publicaSistema(commit, dispatch, payload)
        },

        deletarSistema({commit, dispatch}, payload) {
            SistemaService.deletarSistema(commit, dispatch, payload)
        }
    }
}