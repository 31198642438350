<template>
  <div class="card column is-12 is-pulled-left">
    <filtro tipo="buscarListaSistemas"></filtro>
    <div class="column is-12 is-pulled-left is-marginless is-paddingless">
      <div class="table-scroll">
        <table class="table is-fullwidth is-hoverable is-striped">
          <div
            class="col-acao columns is-centered"
            v-if="listaSistemas.length === 0"
            style="margin:150px; font-size:16px"
          >
            <p>
              Não há sistema cadastrado.
            </p>
          </div>
          <thead v-if="listaSistemas.length !== 0">
            <tr>
              <th>Nome</th>
              <th>Descrição</th>
              <th class="col-acao has-text-centered">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lista, i) in listaFiltrada" :key="i">
              <td>{{ lista.nome }}</td>
              <td>{{ lista.descricao }}</td>
              <td class="col-acao has-text-centered">
                <acoesTabela
                  :id="lista.id"
                  cadastro="CadastroSistema"
                  :lista="lista"
                  :editar-selecao="editarSelecao"
                >
                </acoesTabela>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal v-if="showModal" :dadosMenu="menuSelecionado"> </modal>
  </div>
</template>

<script>
import filtroGet from "../../components/filtroGet";
import acoesTabela from "../../components/acoesTabelaSistema.vue";

export default {
  components: {
    filtro: filtroGet,
    acoesTabela,
  },
  props: {
    editarSistemaSelecionado: Function,
    editarSelecao: Function,
  },
  computed: {
    showModal() {
      return this.$store.state.showModal;
    },
    listaSistemas() {
      return this.$store.state.sistemaModulo.listaSistemas;
    },
    listaFiltrada() {
      return this.$store.state.listaFiltrada;
    },
    obterLista() {
      return this.$store.dispatch("buscarListaSistemas");
    },
    atualizarTabela() {
      return this.$nextTick(() => {
        this.$store.dispatch("buscarListaSistemas");
      });
    },
  },
  mounted() {
    this.obterLista;
  },
  updated() {
    this.atualizarTabela;
  },
};
</script>

<style lang="scss" scoped>
.btn-position {
  position: inherit;
}
.col-acao {
  max-width: 50px !important;
}
</style>
