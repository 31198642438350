<template>
  <div id="janelaNavbar">
    <div>
      <div class="is-pulled-left has-margin-top-5 botoesNavegador">
        <i  class="fas fa-arrow-circle-left" aria-hidden="true"></i>
        <i  class="fas fa-arrow-circle-right" aria-hidden="true"></i>
        <i  class="fas fa-redo" aria-hidden="true"></i>
      </div>
      <div class="is-pulled-left campoEndereco">
        <i  class="fas fa-lock is-pulled-left has-margin-left-5" aria-hidden="true"></i>
        <span class="barraDeEndereco">https://www.alterdata.com.br</span>
        <i  class="fas fa-star has-margin-left-10 has-margin-right-5 is-pulled-right" aria-hidden="true"></i>
      </div>
      <i class="fas fa-ellipsis-h is-pulled-right has-margin-10" aria-hidden="true"></i>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
};
</script>

<style lang="scss" scoped>
#janelaNavbar {
  margin: auto;
  width: 100%;
  height: 200px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  border: 1px solid gray;
  border-radius: 5px;
}

#navbar {
  position: relative;
}
.botoesNavegador,
.barraEndereco{
  height: 16px !important;
  margin: 0 5px;
  i {
    margin: 0 5px;
  }
}

.campoEndereco {
  border: solid 1px gray;
  width: calc(100% - 130px);
  margin: 4px 0 3px 5px;
  padding: 2px;
  border-radius: 10px;
}

.barraDeEndereco {
  padding-left: 10px;
  float: left;
  font-size: 12px;
  font-weight: bolder;
  user-select: none;
}
</style>