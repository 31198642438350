export default class DropDownCadastro {
    constructor(objeto) {
        this.text = ""
        this.clickEvent = ""
        this.position = ""
        this.hideOnDevice = ""
        this.customClass = ""
        this.idElement = ""
        this.forceNavbarTop = ""
        this.classesToPreventCloseOnClick = []
        this.idMenu = ""
        Object.assign(this, objeto);
    }

    dropDownCadastroProps() {
        return {
            coluna: false,
            dropDown: true,
            customElement: false,
            avatar: false,
            customItem: false
        }
    }
}