<template>
    <div class="column is-12 is-pulled-left is-paddingless">
        <div class="column is-12 is-pulled-left is-marginless is-paddingless has-margin-bottom-10">
            <div class="column is-9 is-pulled-left is-paddingless has-padding-top-10 has-padding-right-10">
                <input 
                    class="input InputPesquisa" 
                    type="text" 
                    placeholder="Digite a Identificação (id) para pesquisa." 
                    v-model="txtPesquisa"
                    @keyup="executarFiltro(txtPesquisa)"/> 
                <i  class="fas fa-search" aria-hidden="true"></i>
            </div>
            <div class="column is-3 is-pulled-left is-paddingless has-padding-top-10 has-padding-left-10">
                <button type="button" class="btnObterLista button is-fullwidth" @click="obterLista()">
                <i  class="fas fa-sync has-padding-right-5" aria-hidden="true"></i>Atualizar Lista</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props:{
        tipo: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            filtro: [],
            txtPesquisa: '',
            listaComParse: [],
        }
    },
    computed: {
        listaFiltrada(){
            return this.$store.state.listaFiltrada
        }
    },
    methods: {
        executarFiltro() {
            this.listaComParse = this.listaFiltrada
                    
            setTimeout(() =>{
                this.filtro = this.listaComParse.filter(this.filtroTabela)
                this.$store.commit('listaFiltrada', this.filtro)
            }, 1000)
        },

        filtroTabela(linhaFiltrada) {
            return linhaFiltrada.id.includes(this.txtPesquisa)
        },

        obterLista() {
            this.$store.dispatch(this.tipo)
        }
    }
};

</script>
<style lang="scss" scoped>
.InputPesquisa {
    padding-left: 35px;
}

.fa-search {
    float: left;
    margin-left: 8px;
    margin-top: -28px;
    position: relative;
    z-index: 2;
    color: rgb(114, 112, 112);
}

.btnObterLista {
    min-width: 160px;
}
</style>