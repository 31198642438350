import axios from 'axios'
import config from 'config'

function buscarListaSistemas(commit, dispatch) {
    commit("exibirOcultarModalLoading", true)
    
    return axios(`${config.apiUrl}/sistema`)
        .then(function (response) {
            commit('listaSistemas', response.data)
            commit('listaFiltrada', response.data)
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            commit("exibirOcultarModalLoading", false)
        })
}

function buscarSistema(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    return axios(`${config.apiUrl}/sistema/${payload}`)
        .then(function (response) {
            commit('listaSistemas', response.data)
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            commit("exibirOcultarModalLoading", false)
        })
}

function publicaSistema(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    let parametroEndPoint = payload.idSistema === undefined ? 'sistema' : 'sistema/' + payload.idSistema
    const requestOptions = {
        method: payload.idSistema === undefined ? 'post' : 'put',
        data: JSON.stringify(payload.parametros),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data) {
            return data;
        }]
    }
    return axios(`${config.apiUrl}/${parametroEndPoint}`,requestOptions)
        .then(function (response) {
            dispatch('montarMensagemSucesso')
            commit('tipoCrud', response.config.method)
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            commit("exibirOcultarModalLoading", false)
        })
}

function deletarSistema(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
   
    const requestOptions = {
        method: 'delete',
        data: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data) {
            return data;
        }]
    }
    return axios(`${config.apiUrl}/sistema/${payload}/true`, requestOptions)
        .then(function (response) {
            dispatch('montarMensagemSucesso')
            commit('tipoCrud', response.config.method)
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            commit("exibirOcultarModalLoading", false)
        })
}

export const SistemaService = {
    buscarListaSistemas,
    buscarSistema,
    publicaSistema,
    deletarSistema
}
