<template>
  <div>
    <button class="btn-tabela" @click="editarSelecao(cadastro, lista)">
      <i class="fas fa-edit" aria-hidden="true"></i>
    </button>
    <button class="btn-tabela" @click="exibirModal(id)">
      <i class="fas fa-trash" aria-hidden="true"></i>
    </button>
    <ModalConfirmacao
      :mensagem="mensagem"
      :class="exibirModalConfirmacao ? 'is-active' : ''"
      @modalFechado="fecharModal"
    >
    </ModalConfirmacao>
  </div>
</template>
<script>
import ModalConfirmacao from "@/components/modal/modalConfirmacao";

export default {
  components: {
    ModalConfirmacao,
  },
  props: {
    cadastro: String,
    lista: Object,
    id: String,
    editarSelecao: Function,
  },
  data() {
    return {
      mensagem: "",
      exibirModalConfirmacao: false,
      confirmacaoExclusao: false,
    };
  },
  methods: {
    async carregarSistemas() {
      const response = await this.$store.dispatch("buscarListaSistemas");
      this.$store.listaSistemas = response.data;
    },

    async excluirSistema(id) {
      const response = await this.$store.dispatch("deletarSistema", id);
      this.carregarSistemas(response);
    },

    exibirModal(id) {
      this.mensagem =
        "Este sistema pode estar sendo utilizado para cadastrar um menu, que também será deletado junto com os seus itens. Deseja realmente continuar?";
      this.exibirModalConfirmacao = true;
      this.confirmacaoExclusao = id;
    },
    async fecharModal(confirmaExclusao) {
      this.exibirModalConfirmacao = false;
      if (confirmaExclusao) {
        await this.excluirSistema(this.id);
      }
    },
  },
};
</script>
<style scoped>
.btn-tabela {
  border: none;
  background-color: transparent;
}
i {
  font-size: 14px;
  color: #363636;
}
</style>
